import { Alert } from "@mui/material";
import { toJS } from "mobx";
import { CSSProperties, useCallback, useMemo } from "react";
import { TYPE } from "src/pi/context/IForm";
import { Form } from "src/pi/ui/Form";
import { CheckboxField } from "../../FormFields/CheckboxField";
import { DateRangeField } from "../../FormFields/DateRangeField";
import { MultiReferenceField } from "../../FormFields/ReferenceField/MultiReferenceField";
import { MultiSelectField } from "../../FormFields/SelectField/MultiSelectField";
import { TagsField } from "../../FormFields/TagsField";
import { Container } from "./Container";
import * as api from "@crochik/pi-api";

interface IProps {
    filterForm: Form;
    fieldName: string;
    style?: CSSProperties;
    renderAlways?: boolean;
    onSelect?: (field: api.FormField, value: any, keepOpen?: boolean, delayUpdate?: boolean) => void;
    onClose?: () => void;
    onSort?: (field: api.FormField, reverse: boolean) => void;
    onHideColumn?: (field: api.FormField) => void
}

export function FilterComponent({ style, fieldName, filterForm, onSelect, onSort, onClose, onHideColumn }: IProps) {
    const field = useMemo(() => filterForm?.getField(fieldName)!, [filterForm, fieldName]);

    const selectedValue = useMemo(() => toJS(filterForm?.getValue(field)), [filterForm, field]);

    const keepOpen = useMemo(() => {
        switch (field.type) {
            case TYPE.MULTIREFERENCE:
            case TYPE.MULTISELECT:
            case TYPE.DATERANGE:
            case TYPE.TAGS:
                return true;
            default:
                return false;
        }
    }, [field.type]);

    const delayUpdate = useMemo(() => {
        switch (field.type) {
            case TYPE.MULTIREFERENCE:
            case TYPE.MULTISELECT:
            case TYPE.TAGS:
                return true;
            default:
                return false;
        }
    }, [field.type]);

    const onChange = useCallback(
        (field: api.FormField, value: any) => {
            if (selectedValue !== value) {
                onSelect?.(field, value, keepOpen, delayUpdate);
            }
        },
        [selectedValue, keepOpen, delayUpdate, onSelect]
    );

    const label = useMemo(() => field.label ?? field.name ?? "[no-name]", [field.label, field.name]);

    const removeFilter = useCallback(() => onSelect?.(field, undefined), [onSelect, field]);

    const onSortClicked = useCallback(
        (reverse: boolean) => {
            onSort?.(field, reverse);
            onClose?.();
        },
        [onSort, onClose, field]
    );

    const onHideColumnClicked = useCallback(() => onHideColumn?.(field), [field, onHideColumn])

    const renderFieldComponent = useCallback(() => {
        if (!field) {
            return <Alert severity="error">Invalid field</Alert>;
        }
        switch (field.type) {
            case TYPE.SELECT:
            case TYPE.REFERENCE:
            case TYPE.MULTISELECT:
                return <MultiSelectField field={field} value={selectedValue} onChange={onChange} displayInline />;
            case TYPE.MULTIREFERENCE:
                return <MultiReferenceField field={field} form={filterForm} value={selectedValue} onChange={onChange} displayInline />;
            case TYPE.DATERANGE:
                return <DateRangeField field={field} value={selectedValue} onChange={onChange} />;
            case TYPE.CHECKBOX:
                return <CheckboxField field={field} value={selectedValue} onChange={onChange} />;
            case TYPE.TAGS:
                return <TagsField field={field} value={selectedValue} onChange={onChange} />;
            default:
                return <Alert severity="error">{field.type}</Alert>;
        }
    }, [field, onChange, filterForm, selectedValue]);

    return (
        <Container style={style} disabled={true} label={label} removeFilter={removeFilter} onSort={onSortClicked} onHideColumn={onHideColumnClicked}>
            {renderFieldComponent()}
        </Container>
    );
}
