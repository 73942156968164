import { Alert } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Operator } from "@crochik/pi-api";
import { Form } from "src/pi/ui/Form";
import DataService from "../../../../services/DataService";
import { Loading } from "../../Loading";
import { RequestError } from "../../../../api/Client";
import Section from "../Section";
import { AppointmentField } from "../AppointmentField";
import * as api from "@crochik/pi-api";

interface IProps {
    form: Form;
    field: api.FormField;
    value: any;
    onClick: (e: React.MouseEvent) => any;
}

function StandardReadOnlyReferenceField({ field, form, value }: IProps) {
    const [name, setName] = useState<string>();
    const [error, setError] = useState<string>();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (form.isDesigning) {
            setName(field.name!);
            setLoading(false);
            return;
        }

        if (!value) {
            setName(undefined);
            setLoading(false);
            return;
        }

        const options = field.options as api.ReferenceFieldOptions;
        const {  items } = options;
        const objectType = form?.getObjectTypeForReferenceField(options);

        if (!!items && !!items[value]) {
            setName(items[value]);
            setLoading(false);
            return;
        }

        if (!objectType) {
            setError("Bad config");
            setLoading(false);
            return;
        }

        setLoading(true);

        const criteria = form?.updateCriteria(options.criteria);
        criteria.push({
            fieldName: "#id",
            operator: Operator.Eq,
            value,
        });

        DataService()
            .lookupAsync(objectType, { criteria, lookupField: options.foreignFieldName })
            .then((rows) => {
                if (rows.length === 1) {
                    setName(rows[0].value);
                } else {
                    setError("Not found");
                }
                setLoading(false);
            })
            .catch((ex) => {
                if (ex instanceof RequestError) {
                    if (ex.json && "message" in ex.json) {
                        setError(ex.json["message"] as string);
                        setLoading(false);
                        return;
                    }
                }

                const reason = ex as any;
                setError(typeof reason === "string" ? reason : reason["status"]);
                setLoading(false);
            });
    }, [value, field?.options, field?.name, form]);

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (isLoading) {
        return (
            <div style={{ width: 100, height: 24, display: "flex", alignItems: "center" }}>
                <Loading />
            </div>
        );
    }

    return <span>{name ?? value}</span>;
}


export function ReadOnlyReferenceField(props: IProps) {
    const { field, form, value } = props;
    const options = field.options as api.ReferenceFieldOptions;

    if (options?.t === "AppointmentFieldOptions") {
        return (
            <Section title={field.label ?? field.name ?? ""} expanded={!!value}>
                <AppointmentField field={field} value={value} form={form} />
            </Section>
        );
    }

    // TODO: move away from hardcoding, use a different fieldoptions type
    // ...
    switch (options?.objectType) {
        // case "Appointment":
        //     return (
        //         <Section title={field.label ?? field.name ?? ""} expanded={!!value}>
        //             <AppointmentField field={field} value={value} form={form} />
        //         </Section>
        //     );

        // case "UnlayerTemplate":
        //     return titleWithValue(
        //         <div key={field.name} style={{ paddingTop: 6, paddingBottom: 6 }}>
        //             <UnlayerTemplateField field={field} value={value} form={form} disabled={true} />
        //         </div>
        //     );
    }

    if (!value) return null;

    return <StandardReadOnlyReferenceField {...props}/>
}