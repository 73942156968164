import SortIcon from "@mui/icons-material/ArrowUpward";
import FilterIcon from "@mui/icons-material/FilterAlt";
import { Paper, Popover, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TableSortLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { observer } from "mobx-react";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import * as api from "@crochik/pi-api";
import { NumberFieldOptionsStyle } from "@crochik/pi-api";
import * as expr from "../../../context/Expression";
import { Action, TYPE } from "../../../context/IForm";
import ActionButton from "../ActionButton";
import { AddressField } from "../FormFields/AddressField";
import { EmailField } from "../FormFields/EmailField";
import { FieldProps } from "../FormFields/FieldProps";
import { PasswordField } from "../FormFields/PasswordField";
import { PhoneField } from "../FormFields/PhoneField";
import { PostalCodeField } from "../FormFields/PostalCodeField";
import { TextField } from "../FormFields/TextField";
import { DataGridRow } from "./DataGridRow";
import { FilterComponent } from "./FilterComponent";
import { IDataViewComponentProps } from "./IDataViewComponentProps";
import { LocalFilterComponent } from "./LocalFilterComponent";

interface State {
    filterElement?: Element;
    filterField?: string;
    delayedUpdate?: { field: api.FormField; value: string | string[] | undefined };
}

@observer
export class DataGridComponent1 extends React.Component<IDataViewComponentProps, State> {
    private get columns(): number {
        const { view } = this.props;
        if (!view) return 0;

        return view.fields.length + (view.isSelectable ? 1 : 0);
    }

    constructor(props: IDataViewComponentProps) {
        super(props);

        this.state = {};
    }

    sort = (field: string) => (event: React.MouseEvent) => {
        event.stopPropagation();

        const { view } = this.props;
        view.sortByField(field);
    };

    filter = (field: string) => (event: React.MouseEvent) => {
        event.stopPropagation();

        this.setState({
            filterElement: event?.target as Element,
            filterField: field,
        });
    };

    closeFilterPopover = (closeWithoutUpdate?: boolean) => {
        const { delayedUpdate } = this.state;
        if (!closeWithoutUpdate && !!delayedUpdate?.field) {
            this.onFilter(delayedUpdate.field, delayedUpdate.value);
        }

        this.setState({
            filterElement: undefined,
            delayedUpdate: undefined,
        });
    };

    renderHeadCol(field: api.FormField) {
        const { view } = this.props;

        var align: "inherit" | "left" | "center" | "right" | "justify" = "inherit";

        switch (field.type) {
            case TYPE.NUMBER:
                if (field.options && "style" in field.options) {
                    const options = field.options as api.NumberFieldOptions;
                    switch (options.style) {
                        case NumberFieldOptionsStyle.Currency:
                            align = "right";
                            break;

                        case NumberFieldOptionsStyle.Price:
                        case NumberFieldOptionsStyle.Rating:
                        default:
                            break;
                    }
                } else {
                    // by default aligns to the right
                    align = "right";
                }
                break;

            case TYPE.LOCATIONDISTANCE:
                align = "right";
                break;

            default:
                break;
        }

        const isFilterable = view.isFilterable(field);
        const isFiltered = isFilterable && view.isFiltered(field);
        const hasLookupValues = isFilterable && view.hasLocalLookup(field);
        const isSortable = view.isSortable(field);
        const isSorted = view.isSorted(field);
        const isActive = isFiltered || isSorted;
        const direction = isFiltered ? "desc" : isSorted ? (view.isSortReversed ? "asc" : "desc") : "desc";
        const title = isFiltered ? "Filtered" : isSorted ? "Sorted" : isFilterable ? "Filter" : "Sort";
        const icon = isFiltered ? FilterIcon : isSorted ? SortIcon : isFilterable ? FilterIcon : SortIcon;
        const isEnabled = isSortable || isFilterable || hasLookupValues;
        const onClick = field.name ? (isFilterable ? this.filter(field.name) : this.sort(field.name)) : undefined;

        return (
            <TableCell key={field.name} align={align}>
                <Tooltip title={title} enterDelay={300}>
                    <TableSortLabel active={isActive} disabled={!isEnabled} direction={direction} onClick={onClick} IconComponent={icon}>
                        {field.label || field.name}
                    </TableSortLabel>
                </Tooltip>
            </TableCell>
        );
    }

    onSelectAllClick = () => {
        const { view } = this.props;
        const all = view.records.length === view.selectedCount;
        view.selectAll(!all);
    };

    onValueChange = (field: api.FormField, value: any, keepOpen?: boolean, delayUpdate?: boolean) => {
        if (delayUpdate) {
            this.setState({ delayedUpdate: { field, value } });
        } else {
            this.onFilter(field, value);
            if (!keepOpen) {
                this.closeFilterPopover();
            }
        }
    };

    onFilter = (field: api.FormField, value: any) => {
        const { view } = this.props;
        if (!field.name) return;

        if (value === undefined) {
            view.removeFilter(field.name);
            return;
        }

        view.setFilter(field.name, value);
    };

    onSort = (field: api.FormField, reverse: boolean) => {
        const { view } = this.props;
        if (!field.name) return;
        view.sortByField(field.name, reverse);
    };

    onHideColumn = (field: api.FormField) => {
        if (!!field?.name) {
            this.closeFilterPopover(true)
            this.props.view.toggleFieldVisibility(field.name)
        }
    }

    onClosePopover = () => this.closeFilterPopover()

    onShowObjectMenu = (row: object) => (e: React.MouseEvent) => {
        const { onObjectMenu } = this.props;
        onObjectMenu?.(e.target as HTMLElement, row);
    }

    renderHead(): any {
        const { view } = this.props;
        const { visibleFields, isFilterableLocally } = view;
        const rowCount = view.records.length;
        const { filterElement, filterField } = this.state;

        const filterComponent = !filterField ? null : isFilterableLocally ? (
            <LocalFilterComponent dataView={view} fieldName={filterField} renderAlways={true} />
        ) : view.filterForm ? (
            <FilterComponent
                filterForm={view.filterForm}
                fieldName={filterField}
                style={{ maxWidth: 400 }}
                onSelect={this.onValueChange}
                onSort={this.onSort}
                onClose={this.closeFilterPopover}
                onHideColumn={this.onHideColumn}
            />
        ) : null;

        const { canLoadMore, isSelectable, singleObjectActions } = view;
        const canSelectAll = !canLoadMore && rowCount > 0;

        return (
            <TableHead>
                <TableRow>
                    {isSelectable && (
                        <TableCell padding="none" size="small" style={{ width: 42, paddingLeft: 12 }}>
                            {canSelectAll && (
                                <Checkbox
                                    indeterminate={view.selectedCount > 0 && view.selectedCount < rowCount}
                                    checked={view.selectedCount === rowCount}
                                    onChange={this.onSelectAllClick}
                                />
                            )}
                        </TableCell>
                    )}
                    {singleObjectActions && (
                        <TableCell padding="none" size="small" style={{ maxWidth: 42 }} />
                    )}
                    {visibleFields.map((column) => this.renderHeadCol(column))}
                </TableRow>
                <Popover
                    open={!!filterElement}
                    anchorEl={filterElement}
                    onClose={this.onClosePopover}
                    transitionDuration="auto"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    {filterComponent}
                </Popover>
            </TableHead>
        );
    }

    createFieldComponent(props: FieldProps): JSX.Element {
        switch (props.field.type) {
            case TYPE.TEXT:
                return <TextField {...props} />;

            case TYPE.PASSWORD:
                return <PasswordField {...props} />;

            case TYPE.ADDRESS:
                return <AddressField {...props} />;

            case TYPE.EMAIL:
                return <EmailField {...props} />;

            case TYPE.PHONE:
                return <PhoneField {...props} />;

            case TYPE.POSTALCODE:
            case "postalCode":
                return <PostalCodeField {...props} />;

            default:
                return <span>unrecognized type: "{props.field.type}"</span>;
        }
    }

    onSelect = (e: React.MouseEvent, row: object) => {
        const { view } = this.props;

        // handle selection (if selctable)
        view.select(row);
    }

    onFilterChange = (field: api.FormField) => (value: object) => {
        const { view } = this.props;
        if (!view || !field.name) return;

        view.setFilter(field.name, value);
    };

    renderBody() {
        const { view, onClick } = this.props;

        if (!view.records) return;

        const loadedFields: { [name: string]: boolean } = {};
        view.loadedFields?.forEach((x) => (loadedFields[x] = true));

        const data = view.records;

        return (
            <TableBody>
                {data.map((id) => {
                    const row = view.get(id);
                    return (
                        <DataGridRow
                            key={id}
                            loadedFields={loadedFields}
                            row={row}
                            view={view}
                            onClick={onClick}
                            onSelect={this.onSelect}
                            onFilterChange={this.onFilterChange}
                            onPopup={this.onShowObjectMenu(row)}
                        />
                    )
                })}
            </TableBody>
        );
    }

    renderAction(action: Action): JSX.Element | undefined {
        const { view } = this.props;
        const disabled = this.isDisabled(action);

        return (
            <ActionButton
                key={action.name}
                title={action.label ?? action.name ?? "[no-name]"}
                color="primary"
                variant="contained"
                disabled={disabled}
                action={action.action ?? action.name ?? "[no-name]"}
                context={view.context}
            />
        );
    }

    isDisabled(ui: api.UIElement): boolean {
        const { view } = this.props;
        return !expr.evaluate(ui.enable, view.context);
    }

    isHidden(ui: api.UIElement): boolean {
        const { view } = this.props;
        return !expr.evaluate(ui.visible, view.context);
    }

    loadMore = () => {
        const { view } = this.props;
        view.loadMoreAsync();
    };

    renderFooter(): React.ReactNode {
        const { view } = this.props;
        var actions: Action[] = [];

        if (view.actions) {
            for (var action of view.actions) {
                if (!this.isHidden(action)) {
                    actions.push(action);
                }
            }
        }

        if (actions.length < 1) {
            return undefined;
        }

        return (
            <TableFooter>
                <TableRow>
                    <TableCell colSpan={this.columns}>{actions.map((action) => this.renderAction(action))}</TableCell>
                </TableRow>
            </TableFooter>
        );
    }

    renderActions(): React.ReactNode {
        const { view } = this.props;
        var actions: Action[] = [];

        if (view.actions) {
            for (var action of view.actions) {
                if (!this.isHidden(action)) {
                    actions.push(action);
                }
            }
        }

        if (actions.length < 1) {
            return undefined;
        }

        return <div>{actions.map((action) => this.renderAction(action))}</div>;
    }

    render() {
        const { view } = this.props;

        return (
            <Paper
                sx={{
                    overflow: "auto",
                    height: "100%",
                    marginTop: "1px",
                }}
            >
                <Table stickyHeader={true} size="small">
                    {this.renderHead()}
                    {this.renderBody()}
                </Table>
                {!view.isLoading && view.canLoadMore && <Waypoint bottomOffset="-300px" onEnter={this.loadMore} />}
                {this.renderActions()}
            </Paper>
        );
    }
}
