import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Checkbox, Chip, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, TextField } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { toJS } from "mobx";
import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { IReferenceValue } from "src/pi/services/DataService";

export interface AutocompleteBaseProps {
    label: string;
    rows?: IReferenceValue[];
    selectedMap: { [id: string]: IReferenceValue };
    value: string | string[] | undefined;
    onInputChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onValueChange?(value: string | string[] | undefined): void;
    loading?: boolean;
    searchStr: string;
    disabled?: boolean;
}

export function AutocompleteBase(props: AutocompleteBaseProps) {
    const { label, onInputChange, rows, value, onValueChange, loading, selectedMap, searchStr } = props;
    const [selected, setSelected] = useState<IReferenceValue[]>();

    const arraysEqual = (a: string[] | undefined, b: string[] | undefined) => {
        if (a === b) return true;
        if (!a || !b) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    useEffect(() => {
        if (selected !== undefined) {
            let selectedIds: string[] | undefined = undefined;
            if (!!selected.length) {
                selectedIds = selected.map((x) => x.id);
            }
            let parsedValue: string[] | undefined = undefined;
            const valueJS = toJS(value);
            if (valueJS !== undefined) {
                if (!Array.isArray(valueJS)) {
                    parsedValue = [valueJS];
                } else {
                    parsedValue = valueJS;
                }
            }
            if (!arraysEqual(selectedIds, parsedValue)) {
                onValueChange?.(selectedIds);
            }
        }
    }, [selected, value, onValueChange]);

    useEffect(() => {
        if (!value) return;

        const tmp: IReferenceValue[] = [];
        if (Array.isArray(value)) {
            value.forEach((id) => {
                const found = selectedMap[id];
                if (!!found) {
                    tmp.push(found);
                } else {
                    tmp.push({ id, value: id });
                }
            });
        }
        setSelected(tmp);

    }, [value, selectedMap]);

    const onDelete = (row: IReferenceValue) => () => {
        setSelected((x) => {
            let tmp = !!x ? [...x] : [];
            const index = tmp.indexOf(row);
            if (index > -1) {
                tmp.splice(index, 1);
            }
            return tmp;
        });
    };

    const onClear = useCallback(() => setSelected([]), []);

    const onClickListItem = (row: IReferenceValue) => () => {
        setSelected((x) => (!!x ? [...x, row] : [row]));
    };

    const renderListItem = useCallback((row: IReferenceValue, index: number) => {
        return (
            <ListItem key={`item-${row.id}-${index}`} disablePadding>
                <ListItemButton onClick={onClickListItem(row)}>
                    <ListItemIcon>
                        <Checkbox />
                    </ListItemIcon>
                    <ListItemText primary={row.value} />
                </ListItemButton>
            </ListItem>
        );
    }, []);

    const unselected = useMemo(() => {
        if (!rows) return [];

        return rows.filter((row) => {
            if (selected?.find((x) => x.id === row.id)) {
                return false;
            }
            if (row.id === "#exists" || row.id === "#none") {
                return false;
            }
            return true;
        });
    }, [rows, selected]);

    return (
        <div className={css(styles.container)}>
            <div className={css(styles.header)}>
                <div className={css(styles.label)}>{label}</div>
                <div className={css(styles.buttons)}>
                    <IconButton onClick={onClear} disabled={!selected?.length}>
                        <FilterAltOffIcon />
                    </IconButton>
                </div>
            </div>
            {!!selected?.length && (
                <div className={css(styles.selected)}>
                    {selected.map((x, i) => (
                        <Chip
                            key={`selected-${x.id}-${i}`}
                            label={x.value}
                            onDelete={onDelete(x)}
                            sx={{ marginRight: 1, marginBottom: 1, cursor: "pointer", minWidth: 60 }}
                        />
                    ))}
                </div>
            )}
            <div className={css(styles.input)}>
                <TextField value={searchStr} onChange={onInputChange} label="Search" sx={{ width: "100%" }} variant="filled" autoFocus />
            </div>
            <div>
                {loading ? (
                    <div className={css(styles.skeletonContainer)}>
                        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                    </div>
                ) : (
                    <List sx={{ maxHeight: 300, width: 300, overflow: "auto" }}>{unselected.map((row, i) => renderListItem(row, i))}</List>
                )}
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 300,
        width: "100%",
    },
    header: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        padding: 8,
    },
    buttons: {
        display: "flex",
    },
    label: {
        marginLeft: 8,
        fontSize: 16,
    },
    input: {
        paddingLeft: 12,
        paddingRight: 12,
    },
    selected: {
        paddingTop: 4,
        paddingLeft: 12,
        paddingBottom: 6,
        maxHeight: 160,
        overflow: "auto",
    },
    skeletonContainer: {
        height: 300,
        width: 300,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 12,
        paddingRight: 12,
    },
});
