import React from "react";
import DataService, { IReferenceValue } from "../../../../services/DataService";
import { FieldProps } from "../FieldProps";

import AddIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import { Operator } from "@crochik/pi-api";
import * as api from "@crochik/pi-api";

interface IState {
    loading: boolean;
    tags: IReferenceValue[];
    error?: string;
    value?: IReferenceValue[];
}

export class TagsField extends React.Component<FieldProps, IState> {
    constructor(props: FieldProps) {
        super(props);

        const { value } = props;
        const list: IReferenceValue[] = [];
        if (!!value) {
            if (Array.isArray(value)) {
                for (var v of value) {
                    list.push({
                        id: v,
                        value: v
                    });
                }
            } else {
                list.push({
                    id: value,
                    value: value.toString()
                });
            }
        }

        this.state = {
            loading: false,
            tags: [],
            value: list
        };
    }

    async fetch(searchString?: string) {
        const { field } = this.props;
        const options = field.options as api.TagsFieldOptions;

        if (!options.url) {
            this.setState({
                loading: false,
                error: "Missing options"
            });
            return;
        }

        this.setState({ loading: true });

        const criteria = searchString ? [
            {
                fieldName: "#autocomplete",
                operator: Operator.Eq,
                value: searchString
            }
        ] : undefined;

        var rows = await DataService()
            .lookupAsync(options.url, {
                criteria
            })
            .catch((reason) => {
                console.error(reason);
            });

        if (!rows) {
            this.setState({
                loading: false,
                error: "Failed to load"
            });
            return;
        }

        this.setState({
            loading: false,
            tags: rows
        });
    }

    onInputChanged = async (event: React.ChangeEvent<{}>, value: string, reason: string) => {
        switch (reason) {
            case "input":
                await this.fetch(value);
                break;
        }
    };

    // componentDidMount() {
    //     const { loading } = this.state;
    //     const { disabled } = this.props;
    //     if (!disabled && !loading) {
    //         this.fetch();
    //     }
    // }

    // componentDidUpdate(prevProps: Readonly<FieldProps>, prevState: Readonly<IState>, snapshot?: any): void {
    //     if (this.props.value !== prevProps.value) {
    //         this.fetch();
    //     }
    // }

    onValueChange = (event, newValue: IReferenceValue[]) => {
        // distinct
        const dict: { [name: string]: boolean } = {};
        newValue.forEach((x) => (dict[x.id] = true));
        const newTags = Object.keys(dict);

        // add new value to list of possible tags
        const { tags } = this.state;
        newTags.forEach((x) => {
            if (!tags.find((y) => y.id === x)) {
                tags.push({
                    id: x,
                    value: x
                });
            }
        });

        // update state
        this.setState({
            tags,
            value: newTags.map((x) => {
                return {
                    id: x,
                    value: x
                };
            })
        });

        // update value
        const { onChange, field } = this.props;
        if (!!onChange) onChange(field, newTags);
    };

    filter(options: IReferenceValue[], state: FilterOptionsState<IReferenceValue>): IReferenceValue[] {
        const { value } = this.state;

        var inputValue = state.inputValue.toLowerCase();
        return options.filter((x) => {
            if (value?.find((c) => c.id === x.id)) return false;
            if (x.value.toLowerCase().includes(inputValue)) return true;
            return false;
        });
    }

    filterOptions = (options, params) => {
        const filtered = this.filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== "" && !isExisting) {
            filtered.push({
                id: inputValue,
                value: `Add "${inputValue}"`
            });
        }

        return filtered;
    };

    onDelete = (value) => () => {
        console.log(`delete ${value}`);
    };

    renderTag(value: string) {
        const { disabled } = this.props;
        const onDelete = disabled ? undefined : this.onDelete(value);

        return <Chip key={value} label={value} onDelete={onDelete} variant="filled" color="primary" />;
    }

    onOpen = async () => {
        // TODO: wont' work for the first letter typed while closed
        // ...
        return await this.fetch();
    };

    // onOpen = () => {
    //     this.setState({ open: true });
    // }

    // onClose = () => {
    //     this.setState({ open: false }, this.selectText);
    // };    

    render() {
        const { style, disabled } = this.props;
        const { loading, tags, error, value } = this.state;

        if (error) {
            return <div>{error}</div>;
        }

        if (disabled) {
            return (
                <div
                    style={{
                        marginTop: 16,
                        marginBottom: 8
                        // paddingTop: 16,
                        // paddingBottom: 16,
                        // border: "1px solid rgba(0, 0, 0, 0.38)",
                    }}
                >
                    <div style={{
                        display: "flex", flexWrap: "wrap", rowGap: "4px", columnGap: "4px",
                        ...style
                    }}>
                        {value?.map((x) => this.renderTag(x.value))}
                        {!disabled && <Chip icon={<AddIcon />} label="Add" variant="filled" color="default" />}
                    </div>
                </div>
            );
        }

        return (
            <Autocomplete
                autoComplete={true}
                autoHighlight={true}
                clearOnEscape={true}
                selectOnFocus={true}
                onOpen={this.onOpen}
                // onClose={this.onClose}
                style={{
                    ...style,
                    marginTop: 16,
                    marginBottom: 8,
                }}
                onChange={this.onValueChange}
                onInputChange={this.onInputChanged}
                filterOptions={this.filterOptions}
                getOptionLabel={(x) => x.value}
                isOptionEqualToValue={(x, y) => x.id === y.id}
                value={value}
                multiple
                options={tags}
                loading={loading}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Tags"
                        variant="filled"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
        );
    }
}
