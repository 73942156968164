import { Alert, Link } from "@mui/material";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import App from "src/pi/application/App";

import LaunchIcon from "@mui/icons-material/Launch";
import * as api from "@crochik/pi-api";
import { InputTextField } from "../InputTextField";
import { URI } from "../../../../api/URI";
import { MouseEvent } from "react";

function parseUrlValueLine(line: string, defaultName?: string): { name?: string; url?: string } {
    var url = line;
    var name = defaultName;

    if (line.startsWith("[")) {
        const index = line.indexOf("]");
        if (index < 0)
            return {
                name: `Invalid: ${line}`,
            };

        url = line.substring(1, index);
        if (line.length > index + 1) {
            name = line.substring(index + 1).trim();
            if (name.startsWith("(") && name.endsWith(")")) {
                name = name.substring(1, name.length - 1);
            }
        }
    }

    try {
        const urlObj = new URI(url);

        switch (urlObj.scheme) {
            case "mailto:":
            case "https:":
            case "http:":
            case "ftp:":
                return {
                    name,
                    url,
                };

            default:
                return {
                    name: line,
                };
        }
    } catch (e) {
        return {
            name: line,
        };
    }
}

function parseUrlValue(value: any, options?: api.URLFieldOptions) {
    var list: undefined | { name?: string; url?: string }[] = undefined;

    if (value) {
        if (options?.multline) {
            if (Array.isArray(value)) {
                list = value.map((x) => parseUrlValueLine(x));
            } else {
                list = value
                    .toString()
                    .split("\n")
                    .map((x) => parseUrlValueLine(x));
            }
        } else {
            list = [parseUrlValueLine(value.toString())];
        }
    }

    return list;
}

export function ReadOnlyUrl({ field, value }: { field: api.FormField, value: any }) {
    const openUrl = (url) => (e: MouseEvent) => {
        e.stopPropagation();
        App().openInNewTab(url);
    };

    const list = parseUrlValue(value, field?.options as api.URLFieldOptions);

    const renderLink = (item: { name?: string; url?: string }, index: number) => {
        if (!item.url) return <Alert severity="error">{item.name}</Alert>;

        if (!item.name)
            return (
                <div key={`url-field-${index}`} style={{ width: '100%', position: 'relative' }}>
                    <Link href="#" onClick={openUrl(item.url)} underline="hover">
                        <div style={{ display: "flex", alignItems: "center", paddingTop: 6, paddingBottom: 6, width: '100%', position: 'relative' }}>
                            <LaunchIcon style={{ marginRight: 6 }} />
                            {/*{item.url}*/}
                        </div>
                    </Link>
                </div>
            );

        return (
            <div key={`url-field-${index}`} style={{ width: '100%', position: 'relative' }}>
                <Link href="#" onClick={openUrl(item.url)} title={item.url} underline="hover">
                    <div style={{ display: "flex", alignItems: "center", paddingTop: 6, paddingBottom: 6, width: '100%', position: 'relative' }}>
                        <LaunchIcon style={{ marginRight: 6 }} />
                        {item.name}
                    </div>
                </Link>
            </div>
        );
    }

    if (!list) return null;
    return <>{list?.map((x, i) => renderLink(x, i))}</>;
}

export class URLField extends InputTextField {
    openUrl = (url) => () => {
        App().openInNewTab(url);
    };

    get inputLabelProps() {
        return {
            required: this.props.field.isRequired,
            error: this.props.error ? true : false,
            htmlFor: this.props.field.name,
            shrink: true,
        };
    }

    renderLink(item: { name?: string; url?: string }, index: number) {
        if (!item.url) return <Alert severity="error">{item.name}</Alert>;

        if (!item.name)
            return (
                <div key={`url-field-${index}`}>
                    <Link href="#" onClick={this.openUrl(item.url)} underline="hover">
                        <div style={{ display: "flex", alignItems: "center", paddingTop: 6, paddingBottom: 6 }}>
                            <LaunchIcon style={{ marginRight: 6 }} />
                            {/*{item.url}*/}
                        </div>
                    </Link>
                </div>
            );

        return (
            <div key={`url-field-${index}`}>
                <Link href="#" onClick={this.openUrl(item.url)} title={item.url} underline="hover">
                    <div style={{ display: "flex", alignItems: "center", paddingTop: 6, paddingBottom: 6 }}>
                        <LaunchIcon style={{ marginRight: 6 }} />
                        {item.name}
                    </div>
                </Link>
            </div>
        );
    }

    renderTextField(): JSX.Element | null {
        const options = this.props.field.options as api.URLFieldOptions;

        var props: TextFieldProps = {
            ...this.calcProps(),
            fullWidth: true,
            type: "text",
        };

        if (!!options && options.multline) {
            // prevent auto-submit and allow multiple lines
            props = {
                ...props,
                multiline: true,
            };

            delete props.onKeyPress;
        }

        return <MuiTextField {...props} variant="filled" />;
    }

    render() {
        const { style, disabled, value, field } = this.props;
        const options = field.options as api.URLFieldOptions;

        if (!disabled) return this.renderTextField();
        if (!value) return null;

        const list = parseUrlValue(value, options);

        const computedStyle = style ?? {
            paddingTop: 16,
            paddingBottom: 8,
            paddingLeft: 12,
            paddingRight: 12,
            border: "1px solid rgba(0,0,0,.26)",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: 4,
            marginTop: disabled ? 8 : 0,
            marginBottom: 8,
        };

        return <div style={computedStyle}>{list?.map((x, i) => this.renderLink(x, i))}</div>;
    }
}
