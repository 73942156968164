import VisibleIcon from "@mui/icons-material/Visibility";
import HiddenIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
    DragDropContext,
    Draggable,
    DraggableLocation,
    DraggableProvided,
    DraggableRubric,
    DraggableStateSnapshot,
    Droppable,
    DropResult,
    ResponderProvided
} from "react-beautiful-dnd";
import { DataView } from "../../DataView";
import SortIcon from "@mui/icons-material/ArrowUpward";
import ReverseSortIcon from "@mui/icons-material/ArrowDownward";
import * as api from "@crochik/pi-api";

interface IProps {
    dataView: DataView;
}

export function FieldsComponent(props: IProps) {
    const { dataView } = props;
    const { orderedFields } = dataView;
    const [fieldsList, setFieldsList] = useState<api.FormField[]>(orderedFields);
    const [showNames, setShowNames] = useState(false);

    useEffect(() => {
        updateFields();
    }, []);

    const updateFields = () => {
        console.log("updateFields");

        const hiddenFields = orderedFields.filter(x => !x.isVisible).sort((a, b) => (a.label ?? a.name ?? "")?.localeCompare((b.label ?? b.name ?? "")));
        const fields: api.FormField[] = [
            ...orderedFields.filter(x => x.isVisible),
            ...hiddenFields
        ];
        setFieldsList(fields);
    };

    const toggleVisibility = (x: api.FormField) => () => {
        if (!dataView || !x.name) return;
        dataView.toggleFieldVisibility(x.name);

        // this.forceUpdate();
        updateFields();
    };

    const reorder = (source: DraggableLocation, destination: DraggableLocation) => {
        const result = Array.from(fieldsList);
        const [removed] = result.splice(source.index, 1);
        result.splice(destination.index, 0, removed);

        const order = result.filter((x) => !!x.name).map((x) => x.name as string);
        console.log("new order", order);
        dataView.reoderFields(order);

        setFieldsList(result);
        // updateFields();
    };

    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        const { source, destination } = result;

        if (!destination) {
            console.log("drop outside");
            return;
        }

        console.log(`move ${source.index} to ${destination.index}`);
        reorder(source, destination);
        // this.forceUpdate();

        // updateFields();
    };

    const onSort = (x: api.FormField, reverse: boolean) => () => {
        dataView.sortByField(x.name!, reverse);
    };

    const renderChild = (x: api.FormField) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
        const style: React.CSSProperties = {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            userSelect: "none",
            justifyContent: "space-between",
            paddingLeft: 12,
            // padding: 10,
            // border: '1px solid blue',
            // marginBottom: 10,
            background: snapshot.isDragging ? "yellow" : "white",
            ...provided.draggableProps.style
        };

        const isSortable = dataView.isSortable(x);
        const isSorted = isSortable && dataView.isSorted(x);
        const isSortedReverse = dataView.isSortReversed;

        return (
            <Paper key={x.name} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                   style={style}>
                <div>
                    <Typography>{x.label || x.name}</Typography>
                    {
                        showNames && !!x.label && x.label !== x.name &&
                        <>
                            <Typography color="gray" fontSize={10}>{x.name}</Typography>
                        </>
                    }
                </div>
                <div>
                    {
                        isSortable && (
                            <>
                                <IconButton onClick={onSort(x, false)} size="small"><SortIcon color={isSorted && !isSortedReverse ? "primary" : undefined} /></IconButton>
                                <IconButton onClick={onSort(x, true)} size="small"><ReverseSortIcon color={isSorted && isSortedReverse ? "primary" : undefined}/></IconButton>
                            </>
                        )
                    }
                    <IconButton onClick={toggleVisibility(x)}>{x.name && dataView.isFieldVisible(x.name) ?
                        <VisibleIcon /> : <HiddenIcon />}</IconButton>
                </div>
            </Paper>
        );
    };

    const renderChildren = (shadow) => (provided, snapshot) => {
        // const { orderedFields } = dataView;
        // const fields = orderedFields;
        const style = { width: "100%", minHeight: "100px" }; //, backgroundColor: snapshot.isDraggingOver ? 'green' : 'white' }

        const fields = fieldsList;

        return (
            <Stack spacing={1} ref={provided.innerRef} style={style} {...provided.droppableProps}>
                {fields.map((x, i) => {
                    return (
                        <Draggable key={x.name} draggableId={x.name ?? i.toString()} index={i}>
                            {renderChild(x)}
                        </Draggable>
                    );
                })}
                {provided.placeholder}
            </Stack>
        );
    };

    console.log("render");

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
                key="container"
                droppableId="container"
                // isDropDisabled={true}
                // renderClone={this.cloneItem(this.tools)}
            >
                {renderChildren(false)}
            </Droppable>
        </DragDropContext>
    );
}
