import ExpandIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Typography } from "@mui/material";
import { CSSProperties, useState } from "react";

interface IProps {
    title: string;
    expanded?: boolean;
    children?: React.ReactNode;
    style?: CSSProperties;
}

export default function Section({ title, children, style, expanded }: IProps) {
    const [isExpanded, setExpanded] = useState(expanded ?? true);
    const color = "#77777722";

    const toggle = () => {
        setExpanded(!isExpanded);
    };

    return (
        <div
            style={{
                borderLeft: `4px solid ${color}`,
                borderBottom: `2px solid ${color}`,
                marginBottom: 8,
                marginTop: 12,
                width: "100%",
                position: "relative"
            }}
            className="Section"
        >
            <div
                onClick={toggle}
                style={{
                    cursor: "pointer",
                    paddingLeft: 12,
                    paddingTop: 4,
                    paddingBottom: 4,
                    background: `linear-gradient(90deg, ${color}, #ffffff)`,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
                className="Section_Header"
            >
                <Typography fontSize="14px" color="#777777">
                    {title}
                </Typography>
                <ExpandIcon style={{
                    color: "#77777777",
                    marginRight: 12,
                    transform: isExpanded ? "rotate(180deg)" : undefined
                }} />
            </div>
            <div style={{
                backgroundColor: "white", display: isExpanded ? "block" : "none", position: "relative", paddingLeft: 12, 
                ...style,
            }} className="Section_Body">
                {children}
            </div>
        </div>
    );
}