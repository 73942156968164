import React from "react";

import { toJS } from "mobx";
import { observer } from "mobx-react";

import MoneyIcon from "@mui/icons-material/AttachMoney";
import CheckOn from "@mui/icons-material/Check";
import CheckOff from "@mui/icons-material/Close";
import Empty from "@mui/icons-material/DoNotDisturbAlt";
import FilterOff from "@mui/icons-material/FilterAltOff";
import { Checkbox, IconButton, List, ListItem, ListItemText, Rating, Typography } from "@mui/material";

import { NumberFieldOptionsStyle } from "@crochik/pi-api";
import { TYPE } from "../../../context/IForm";
import { DataView, ILookupValue } from "../../DataView";
import { Loading } from "../Loading";
import * as api from "@crochik/pi-api";

interface IProps {
    dataView: DataView;
    fieldName: string;
    maxHeight?: number;
    renderAlways?: boolean;
    onSelect?: () => void;
}

@observer
export class LocalFilterComponent extends React.Component<IProps> {
    toggle = (value: ILookupValue) => () => {
        const { dataView, fieldName } = this.props;
        dataView.toggleLocalFilter(fieldName, value);
    };

    removeFilter = () => {
        const { dataView, fieldName } = this.props;
        dataView.removeFilter(fieldName);
    };

    renderContent(field: api.FormField, content: JSX.Element) {
        const { maxHeight } = this.props;
        const label = field?.label || field?.name;
        const isActive = true;

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: maxHeight,
                    // maxWidth: '300px',
                    overflow: "hidden",
                    padding: "8px",
                    backgroundColor: "#efefef",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography color="primary">{label}</Typography>
                    <IconButton disabled={!isActive} onClick={this.removeFilter}>
                        <FilterOff />
                    </IconButton>
                </div>
                {content}
            </div>
        );
    }

    render() {
        const { dataView, fieldName, renderAlways } = this.props;

        const field = dataView.fields.find((x) => x.name === fieldName);
        if (!field) return null;

        const lookup = toJS(dataView.getFieldLookup(fieldName));
        if (dataView.canLoadMore || !lookup) {
            const value = dataView.filterForm?.getValue(field);

            const bodyContent = (
                <div
                    style={{
                        overflow: "auto",
                        height: "240px",
                        width: "200px",
                    }}
                >
                    {value && (
                        <ListItem
                            key={value}
                            onClick={this.removeFilter}
                            style={{
                                paddingTop: 2,
                                paddingBottom: 2,
                                cursor: "pointer",
                                backgroundColor: "white",
                                // backgroundColor: isSelected ? '#ffffc0' : '#ffffff',
                            }}
                        >
                            <ListItemText primary={value} />
                        </ListItem>
                    )}
                </div>
            );

            return this.renderContent(field, bodyContent);
        }

        const values = Object.values(lookup).sort((a, b) => (a.value < b.value ? -1 : 1));
        if (!renderAlways && values.length < 2) return null;

        const filter = (dataView.getFilter(fieldName) || []).map((x) => x.name);
        const isActive = dataView.filteredFields.indexOf(fieldName) >= 0;

        let renderValue: (x: ILookupValue) => JSX.Element = (x: ILookupValue) => <ListItemText primary={x.value} />;

        switch (field?.type) {
            case TYPE.NUMBER:
                if (field?.options) {
                    const options = field.options as api.NumberFieldOptions;
                    switch (options.style) {
                        case NumberFieldOptionsStyle.Price:
                            renderValue = (x: ILookupValue) => (
                                <Rating value={x.value as unknown as number} size="small" icon={<MoneyIcon color="primary" />} emptyIcon={<div />} disabled />
                            );
                            break;
                        case NumberFieldOptionsStyle.Rating:
                            renderValue = (x: ILookupValue) => <Rating value={x.value as unknown as number} disabled />;
                            break;
                    }
                }
                break;
            case TYPE.CHECKBOX:
            case "boolean":
                renderValue = (x: ILookupValue) => (!!x.value ? <CheckOn /> : <CheckOff />);
        }

        const renderItem = (x: ILookupValue) => {
            const isSelected = filter.indexOf(x.name) >= 0;
            return (
                <ListItem
                    key={x.name}
                    onClick={this.toggle(x)}
                    style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        cursor: "pointer",
                        backgroundColor: "white",
                        // backgroundColor: isSelected ? '#ffffc0' : '#ffffff',
                    }}
                >
                    <Checkbox edge="start" checked={isSelected || !isActive} tabIndex={-1} disabled={!isActive} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {x.name.length < 1 && x.value !== false ? <Empty /> : renderValue(x)}
                        {isActive ? null : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingLeft: 16,
                                }}
                            >
                                {x.count}
                            </div>
                        )}
                    </div>
                </ListItem>
            );
        };

        const content = dataView.isLoading ? (
            <Loading />
        ) : (
            <div
                style={{
                    overflow: "auto",
                    height: "calc(100% - 32px)",
                }}
            >
                <List>{values.map((x) => renderItem(x))}</List>
            </div>
        );

        return this.renderContent(field, content);
    }
}
