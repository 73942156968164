import MoneyIcon from "@mui/icons-material/AttachMoney";
import CheckOn from "@mui/icons-material/CheckBox";
import CheckOff from "@mui/icons-material/CheckBoxOutlineBlank";
import { Alert, Button, Chip, Link, Rating, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import sanitizeHtml from "sanitize-html";
import * as api from "@crochik/pi-api";
import { NumberFieldOptionsStyle } from "@crochik/pi-api";
import App from "src/pi/application/App";
import {
    TYPE
} from "../../../../context/IForm";
import { ChildrenField } from "../../FormFields/ChildrenField";
import { LabelField } from "../../FormFields/LabelField";
import { ObjectField } from "../../FormFields/ObjectField";
import { ReadOnlyReferenceField } from "../../FormFields/ReferenceField/ReadOnlyReferenceField";
import { RelatedObjectsField } from "../../FormFields/RelatedObjectsField";
import Section from "../../FormFields/Section";
import { ReadOnlyUrl, URLField } from "../../FormFields/URLField";
import { FormCellProps } from "./FormCell";
import { UnknownValue } from "../../../ObjectTypeDesigner/UnknownValue";
import { Dump } from "../../../../Dump";

function renderDate(type: string, value: any): string {
    let date: Date;
    if (value instanceof Date) date = value;
    else if (typeof value === "string") date = new Date(value);
    else if (value === undefined || value == null) {
        return "";
    } else {
        return `!${value}!`;
    }

    switch (type) {
        case TYPE.DATE:
            return date.toLocaleDateString();
        case TYPE.TIME:
            return date.toLocaleTimeString();
        case TYPE.DATETIME:
        default:
            return date.toLocaleString();
    }
}

function ReadOnlyLocation({ field, value }: { field: api.FormField, value: any }) {
    if (!value) return (
        <div style={{ padding: 8, display: "flex", width: "100%", overflow: "auto" }}>&nbsp;</div>
    );

    if (!("coordinates" in value) || value["type"] !== "Point") return (
        <Alert severity="error">Invalid Location</Alert>
    );

    const { coordinates } = value as any;

    const url = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue%7C${coordinates[1]},${coordinates[0]}&size=400x400&zoom=14&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    return (
        <div style={{ padding: 8, display: "flex", width: "100%", overflow: "auto" }}>
            <img src={url} alt="map" />
        </div>
    );
}

function ReadOnlyLocationDistance({ field, value }: { field: api.FormField, value: any }) {
    const isMetric = false;

    if (!value) return null;

    try {
        const km = parseFloat(value);
        if (isMetric) {
            return <span>{(km / 1000).toFixed(1)} km</span>;
        } else {
            return <span>{(km / 1609).toFixed(1)} mi</span>;
        }
    } catch {
        return null;
    }
}

function ReadOnlyRating({ options, value: fieldValue }: { options: api.NumberFieldOptions, value: any }) {
    const { style, maximum, minimum } = options ?? {};

    let value = fieldValue;

    if (typeof value === "string") {
        try {
            value = parseFloat(value);
        } catch {
            console.error(`Invalid value: ${fieldValue}`);
        }
    }

    if (typeof value !== "number") {
        console.error(`Unexpected value for rating field: ${value}`);
        return <span>{value}</span>;
    }

    switch (style) {
        case api.NumberFieldOptionsStyle.Price:
            // special case 
            return (
                <Rating
                    value={value}
                    size="small"
                    icon={<MoneyIcon color="primary" />}
                    emptyIcon={<MoneyIcon color="disabled" />}
                    disabled={true}
                />
            );

        default:
            // TODO: handle min
            // do value-min ?
            // ...
            return (
                <Rating
                    value={value}
                    disabled={true}
                    max={maximum ?? undefined}
                    size="small"
                />
            );
    }
}

function Tags({ field, value }: { field: api.FormField, value: any }) {
    return (<div
            style={{
                marginTop: 16,
                marginBottom: 8
            }}
        >
            <div style={{ display: "flex", flexWrap: "wrap", rowGap: "4px", columnGap: "4px" }}>
                {value?.map((x) => <Chip key={x} label={x} variant="filled" />)}
            </div>
        </div>
    );
}

function Label({ field, value }: { field: api.FormField, value: any }) {
    const labelOptions = field.options as api.LabelFieldOptions;
    const { style } = labelOptions ?? {};
    if (style === api.LabelStyle.Html) {
        const clean = sanitizeHtml(value, {
            allowedTags: ["p", "em", "strong", "b", "i", "span"],
            // allowedAttributes: {
            //     'p': ["style"],
            // },
            allowedStyles: {
                "*": {
                    // Match HEX and RGB
                    color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                    "text-align": [/^left$/, /^right$/, /^center$/]
                    // Match any number with px, em, or %
                    // 'font-size': [/^\d+(?:px|em|%)$/]
                }
                // 'p': {
                //     'font-size': [/^\d+rem$/]
                // }
            }
        });

        return <div style={{ maxHeight: 300, overflow: "auto" }} dangerouslySetInnerHTML={{ __html: clean }} />;
    }

    var variant: Variant = "body1";
    var color = "initial";
    switch (labelOptions.color) {
        case api.PalletColor.Error:
            color = "error";
            break;
        case api.PalletColor.Primary:
            color = "primary";
            break;
        case api.PalletColor.Secondary:
            color = "secondary";
            break;
        case api.PalletColor.TextPrimary:
            color = "textPrimary";
            break;
        case api.PalletColor.TextSecondary:
            color = "textSecondary";
            break;
    }

    switch (labelOptions.style) {
        case api.LabelStyle.Header:
            variant = "h6";
            break;
        case api.LabelStyle.Normal:
            variant = "body1";
            break;
        case api.LabelStyle.Subheader:
            variant = "subtitle1";
            break;
        case api.LabelStyle.Subheader2:
            variant = "subtitle2";
            break;
    }

    if (labelOptions.multline) {
        const lines = value.split("\n");
        return (
            <>
                {lines.map((x, i) => (
                    <div key={`line-${i}`}>
                        <Typography color={color} variant={variant}>
                            {x}
                        </Typography>
                    </div>
                ))}
            </>
        );
    }

    if (labelOptions.style === api.LabelStyle.Button && labelOptions.linkUrl) {
        // link should be handled by container (e.g. readonlyformcell)
        const buttonColor = labelOptions.color === api.PalletColor.Secondary ? "secondary" : "primary";
        return (
            <Button color={buttonColor} variant="outlined">
                {value}
            </Button>
        );
    }

    return (
        <Typography color={color} variant={variant}>
            {value}
        </Typography>
    );
}

function ReadOnlyText({ field, value }: { field: api.FormField, value: any }) {
    const textOptions = field.options as api.TextFieldOptions;

    if (textOptions && textOptions.multline && typeof value === "string") {
        if (textOptions.contentType === "text/html") {
            const clean = sanitizeHtml(value, {
                allowedTags: ["p", "em", "strong", "b", "i", "span"],
                // allowedAttributes: {
                //     'p': ["style"],
                // },
                allowedStyles: {
                    "*": {
                        // Match HEX and RGB
                        color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                        "text-align": [/^left$/, /^right$/, /^center$/]
                        // Match any number with px, em, or %
                        // 'font-size': [/^\d+(?:px|em|%)$/]
                    }
                    // 'p': {
                    //     'font-size': [/^\d+rem$/]
                    // }
                }
            });

            return <div className="ReadOnlyText" style={{ maxHeight: "300px", overflow: "auto" }}
                        dangerouslySetInnerHTML={{ __html: clean }} />;
        }

        var lines = (value as string).split("\n");
        if (lines.length > 1) {
            return (
                <div className="ReadOnlyText"
                     style={{ maxHeight: "300px", overflow: "auto", backgroundColor: "#fcfcfc" }}>
                    {lines.map((l, i) => (
                        <span key={i}>
                            {l}
                            <br />
                        </span>
                    ))}
                </div>
            );
        }
    }

    return <span className="ReadOnlyText">{value ?? ""}</span>;
}


export function ReadOnlyFieldValue({ field, value, row }: { field: api.FormField, value: any, row: object }) {
    const { type } = field;

    switch (type) {
        case TYPE.DATE:
        case TYPE.TIME:
        case TYPE.DATETIME:
            return <span>{renderDate(type, value)}</span>;

        case TYPE.NUMBER:
            const numberOptions = field.options as api.NumberFieldOptions;
            if (numberOptions) {
                switch (numberOptions?.style) {
                    case NumberFieldOptionsStyle.Currency: {
                        if (value === undefined || value === null) return null;
                        try {
                            let numberValue = value;
                            if (typeof (numberValue === "string")) {
                                numberValue = parseFloat(value);
                            }

                            if (Number.isNaN(numberValue)) {
                                return <Alert severity="warning">{value}</Alert>;
                            }

                            return <span>{new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                            }).format(numberValue)}</span>;
                        } catch {
                            console.error(`Not a number: ${value}`);
                        }
                        break;
                    }

                    case NumberFieldOptionsStyle.Price:
                    case NumberFieldOptionsStyle.Rating:
                        return <ReadOnlyRating value={value} options={numberOptions} />;
                }
            }
            break;

        case TYPE.TEXT:
            return <ReadOnlyText field={field} value={value} />;

        case TYPE.CHECKBOX:
        case "boolean":
            return (
                <div
                    key={field.name}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        // marginTop: 6,
                        // marginBottom: 6,
                        backgroundColor: "#fcfcfc",
                        // padding: 8,
                        // borderRadius: 6,
                        height: "100%"
                        // minHeight: 64,
                    }}
                >
                    {!!value ? <CheckOn /> : <CheckOff />}
                    &nbsp;{field.label ?? field.name}
                </div>
            );

        case TYPE.MULTISELECT: // TODO: ...
        case TYPE.SELECT:
            const selectOptions = field.options as api.SelectFieldOptions;
            if (!value) return <span />;
            const { items } = selectOptions;

            const selectName = items ? items[value.toString()] : undefined;
            if (selectName === null || selectName === undefined) {
                return <Alert severity="error">Invalid Option: {value}</Alert>;
            }
            return <span>{selectName}</span>;

        case TYPE.DICTIONARY:
        case TYPE.OBJECT:
        case TYPE.CHILDREN:
            return <Dump style={{
                position: "relative",
                border: "1px solid gray",
                borderRadius: 4,
                padding: 4,
                fontSize: "8pt",
                background: "#eee",
                maxHeight: "200px",
                maxWidth: "300px",
                overflow: "auto"
            }} object={value} />;

        case TYPE.ARRAY:
            if (Array.isArray(value)) {
                return (
                    <ul>{value.map(x => <li>{x.toString()}</li>)}</ul>
                );
            }
            break;

        case TYPE.LABEL:
            return <Label field={field} value={value} />;

        case TYPE.URL:
            return <ReadOnlyUrl field={field} value={value} />;

        case TYPE.TAGS:
            return <Tags field={field} value={value} />;

        case TYPE.LOCATION:
            return <ReadOnlyLocation field={field} value={value} />;

        case TYPE.LOCATIONDISTANCE:
            return <ReadOnlyLocationDistance field={field} value={value} />;

        case TYPE.REFERENCE:
            // TODO: probably should look at options to figure out what is the path of the lookup value
            // ... 
            // this will only work for grids 
            // ...
            const fieldName = `${field.name}|Name`;
            if (fieldName in row) return <span>{row[fieldName] ?? ""}</span>;
            break;
    }

    if (typeof (value) === "object") {
        return <Dump style={{
            position: "relative",
            border: "1px solid gray",
            borderRadius: 4,
            padding: 4,
            fontSize: "8pt",
            background: "#eee",
            maxHeight: "200px",
            maxWidth: "300px",
            overflow: "auto"
        }} object={value} />;
    }

    return <span>{value}</span>;
}

interface ReadOnlyFormCellProps extends FormCellProps {
    value: any;
}

export function ReadOnlyFormCell(props: ReadOnlyFormCellProps) {
    const { field, value, form } = props;

    function renderTag(value: string) {
        return <Chip key={value} label={value} variant="filled" color="primary" />;
    }

    function renderTags(value: string[]) {
        return (
            <div style={{
                display: "flex", flexWrap: "wrap", rowGap: "4px", columnGap: "4px", marginTop: 8, marginBottom: 8
            }}>
                {value?.map((x) => renderTag(x))}
            </div>
        );
    }

    const onClickCell = (field: api.FormField, value: object) => (e: React.MouseEvent) => {
        e.stopPropagation();

        const url = form.getLinkUrl(field);
        if (!url) {
            // ...
            return;
        }

        const openInNewTab = e.ctrlKey || e.metaKey;

        // stand Alone
        if (openInNewTab) {
            App().openInNewTab(url);
        } else {
            App().selectPageAsync(url);
        }
    };

    if (!form) return null;

    const row = (content, title?) => {
        return (
            <div key={field.name} style={{
                marginTop: 8,
                marginBottom: 4,
                backgroundColor: "#fcfcfc",
                padding: 8,
                borderRadius: 6,
                width: "100%",
                position: "relative"
            }} className="ReadOnlyFormCell">
                <Typography variant="subtitle2" color="default">
                    {title || field.label || field.name}
                </Typography>
                {content}
            </div>
        );
    };

    const wrapValue = (content) => {
        return field.options?.linkUrl ? (
            <div key={field.name}>
                <Link onClick={onClickCell(field, value)} underline="hover" color="primary"
                      style={{ cursor: "pointer" }}>
                    {content}
                </Link>
            </div>
        ) : (
            <div key={field.name}>{content ?? <span>&nbsp;</span>}</div>
        );
    };

    const titleWithValue = (content) => {
        return row(wrapValue(content));
    };

    const defaultRow = row(<div style={{ height: "100%" }}>&nbsp;</div>);

    try {
        switch (field.type) {
            case TYPE.DATETIME:
            case TYPE.DATE:
            case TYPE.TIME:
            case TYPE.NUMBER:
            case TYPE.TEXT:
            case TYPE.MULTISELECT: // TODO: ...
            case TYPE.SELECT:
                if (value === undefined || value === null) return defaultRow;
                return titleWithValue(<ReadOnlyFieldValue row={form.values} field={field} value={value} />);

            case TYPE.CHECKBOX:
            case "boolean":
                return (
                    <div key={field.name} style={{
                        marginTop: 8,
                        marginBottom: 4,
                        backgroundColor: "#fcfcfc",
                        padding: 8,
                        paddingTop: 12,
                        paddingBottom: 12,
                        borderRadius: 6
                    }} className="ReadOnlyFormCell">
                        {wrapValue(<ReadOnlyFieldValue row={form.values} field={field} value={value} />)}
                    </div>
                );

            case TYPE.MULTIREFERENCE: // TODO: ...
            case TYPE.REFERENCE: {
                const content = <ReadOnlyReferenceField form={form} field={field} value={value}
                                                        onClick={onClickCell(field, value)} />;
                return titleWithValue(content);
            }

            case TYPE.RELATEDOBJECTS:
                return (
                    <RelatedObjectsField key={field.name} form={form} field={field} value={value} />
                );

            case TYPE.CHILDREN:
                return (
                    <Section title={field.label ?? field.name ?? ""} expanded={!!value} style={{
                        // paddingLeft: 12,
                        width: "100%",
                        maxHeight: "400px",
                        overflow: "auto",
                        position: "relative"
                    }}>
                        <ChildrenField key={field.name} form={form} field={field} value={value} />
                    </Section>
                );

            case TYPE.OBJECT:
                return (
                    <Section title={field.label ?? field.name ?? ""} expanded={!!value}>
                        <ObjectField key={field.name} form={form} field={field} value={value} readonly />
                    </Section>
                );

            case TYPE.URL:
                return row(<URLField key={field.name} field={field} value={value} disabled={true} style={{}} />);

            case TYPE.TAGS: {
                if (!value || (Array.isArray(value) && value.length < 1)) return defaultRow;
                return row(renderTags(value));
            }

            case TYPE.LABEL:
                return (
                    <div key={field.name} style={{ paddingTop: 6, paddingBottom: 6 }}>
                        <LabelField {...props} value={value} />
                    </div>
                );

            case TYPE.DICTIONARY:
                return (
                    <Section title={field.label ?? field.name ?? ""} expanded={!!value}>
                        <Dump style={{ maxHeight: 300, overflow: "auto" }} object={value} />
                    </Section>
                );

            case TYPE.ARRAY:
                if (Array.isArray(value)) {
                    return titleWithValue(<ul>{value.map(x => <li>{x.toString()}</li>)}</ul>);
                }
                return titleWithValue(value);

            case TYPE.LOCATION:
                return (
                    <Section title={field.label ?? field.name ?? ""} expanded={!!value}>
                        <ReadOnlyFieldValue row={form.values} field={field} value={value} />
                    </Section>
                );

            // case TYPE.LOCATIONDISTANCE:

            default:
                if (typeof (value) === "object") {
                    return titleWithValue(
                        <div style={{ maxHeight: 300, overflow: "auto" }}>
                            <UnknownValue value={value} />
                        </div>
                    );
                }
                return titleWithValue(value);
        }

    } catch (e) {
        return titleWithValue(<Alert severity="error">e</Alert>);
    }
}
