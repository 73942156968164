import { action, computed, IReactionDisposer, observable, reaction, runInAction, toJS } from "mobx";

import { Default } from "../context/AppContext";
import { Action, TYPE } from "../context/IForm";

import * as api from "@crochik/pi-api";
import { Condition } from "@crochik/pi-api";
import App, { Breakpoints } from "src/pi/application/App";
import { URI } from "../api/URI";
import * as expr from "../context/Expression";
import DataService from "../services/DataService";

export type OnChangeEvent = (form: Form, field: api.FormField, value: any) => any;

interface IState {
    isDesigning: boolean;
    url?: string;
    values: { [fieldName: string]: any };
    layouts?: api.BreakpointLayouts;
    error?: string;
}

export class Form {
    form: api.Form;
    _context: string;
    title: string;
    fieldMap: { [key: string]: api.FormField } = {};
    errors: object = {};
    disposers: IReactionDisposer[] = [];
    _state: IState;
    _disposer?: IReactionDisposer;

    get url() {
        return this._state.url;
    }

    @computed
    get isDesigning() {
        return this._state.isDesigning;
    }

    set isDesigning(value: boolean) {
        console.log(`isDesigning: ${value}`);
        if (value && !this._state.url) {
            alert(`trying to design form without url: ${this.name}`);
            return;
        }

        this._state.isDesigning = value;
    }

    @computed
    get error() {
        return this._state.error;
    }

    set error(value: string | undefined) {
        this._state.error = value;
    }

    get menu() {
        return this.form.menu;
    }

    get name() {
        return this.form.name;
    }

    get fields() {
        return this.form.fields;
    }

    get actions() {
        return this.form.actions ? this.form.actions : [];
    }

    get isReadOnly() {
        return this.form.isReadOnly;
    }

    get values(): object {
        return this._state.values;
    }

    get objectType() {
        return this.form.objectType;
    }

    @computed
    get hash(): string {
        if (!this.form?.fields || this.form.fields.length === 0) return "";
        return this.form.fields.reduce((p, c) => p + c.name, "");
    }

    @computed
    get layouts(): api.BreakpointLayouts | undefined {
        if (!this._state.layouts) return undefined;
        return toJS(this._state.layouts);
    }

    set layouts(value: api.BreakpointLayouts | undefined) {
        this._state.layouts = value;
    }

    get context(): string {
        return this._context;
    }

    static getValues(formName: string): object | undefined {
        return toJS(Default.state.get(`form.${formName}`));
    }

    static get(formName: string): Form | undefined {
        var form = Default.ui.get(`form.${formName}`);
        if (!form) return undefined;

        if (form instanceof Form) return form;

        console.error("creating form on demand?!?!?!?!");
        return new Form(formName, form, null);
    }

    static create(form: api.Form, url: string | null) {
        return new Form(form.name ?? "[No Name]", form, url);
    }

    static bindAction(name: string, action: string, funct: Function) {
        Default.actions.set(action, funct, `form.${name}`);
    }

    /**
     * add form to the cache, update dataContext and initialize observable state
     * @param name name of the form
     * @param form form
     */
    private constructor(name: string, form: api.Form, url: string | null) {
        this.form = form;
        this._context = `form.${name}`;
        this.title = form.title ?? form.name ?? "Unnamed";

        Default.ui.set(this._context, this);

        const values = Default.state.get(this._context) ?? {}; // ?? observable({});
        const initialState: IState = {
            isDesigning: false,
            url: url ?? undefined,
            values,
            layouts: form.layouts ?? undefined
        };

        this._state = observable(initialState);

        this.init(form);
    }

    unmount() {
        // console.log("disposing", this.disposers.length);
        this.disposers.forEach((x) => x());
        this.disposers = [];

        Default.ui.set(this._context, null);
        Default.state.set(this._context, null);
    }

    private processFields(f: (field: api.FormField) => void) {
        Object.keys(this.fieldMap).forEach((name) => {
            const field = this.fieldMap[name];
            f(field);
        });
    }

    private init(form: api.Form) {
        this.form = form;

        const fields = {};
        this.form.fields?.forEach((field) => {
            if (field.name) fields[field.name] = field;
        });
        this.fieldMap = fields;

        const errors = {};
        const requiredFields: api.FormField[] = [];

        // const values: { [fieldName: string]: any } = {};
        this.processFields(field => {
            const apiName = field.apiName ?? field.name;
            if (!apiName) return;

            this._state.values[apiName] = field.defaultValue;
            errors[apiName] = undefined;

            if (field.isRequired) requiredFields.push(field);
        });

        // update state
        Default.state.set(this._context, this._state.values);
        this.errors = observable(errors);
        this._state.layouts = form.layouts ?? undefined;
        this._disposer?.();

        if (!requiredFields) {
            console.log("no required fields");
            return;
        }

        if (!this.getField("#requiredFields")) {
            this.fieldMap["#requiredFields"] = {
                t: "HiddenField",
                type: TYPE.HIDDEN,
                name: "#requiredFields",
                isVisible: false,
                visible: ["false"]
            };
        }

        const evaluateRequireFields = () => {
            for (let field of requiredFields) {
                if (!field.name) continue;
                if (!field.isVisible || field.isReadOnly) continue;
                if (field.visible && !this.evaluate(...field.visible)) continue;
                if (!this.evaluateRequired(field.name)) {
                    console.log(`evaluation returned false for ${field.name}`);
                    return false;
                }
            }

            console.log("all required fields filled");
            return true;
        };

        this._disposer = reaction(evaluateRequireFields, (value, reaction) => {
            console.log(`${this.form.name}.#requiredFields = ${value}`);
            this._state.values["#requiredFields"] = value;
        });

        const allRequiredFieldsFilled = evaluateRequireFields();
        this._state.values["#requiredFields"] = allRequiredFieldsFilled;
        // console.log("requiredfields", toJS(this.getValues()), allRequiredFieldsFilled);
    }

    async reloadAsync() {
        if (!this.url) {
            console.error("Can't reload, no url");
            return;
        }

        const form = await DataService()
            .dataFormAsync(this.url)
            .catch((response) => {
                console.error(`Failed to get form: ${this.url}}`, response);
            });

        if (!form) return;

        runInAction(() => {
            this.init(form);
        });
    }

    async saveLayoutsAsync(input: object, layouts: api.BreakpointLayouts) {
        const client = App().apiClient;
        if (!client || !this.url) {
            return {
                success: false,
                message: "Invalid state",
                action: "#cancel"
            };
        }

        const request: api.SaveFormLayoutsRequest = {
            ...input,
            layouts
        };

        const uri = new URI(`dataform:${this.url}`);
        const formUrl = uri.getPathAndQuery("/Layout/Save");
        const result = await client.postJson<api.BreakpointLayouts>(formUrl, request);
        if (result) {
            console.log("update layouts");
            this._state.layouts = result;
        }

        return result;
    }

    bindOnFieldValueChange(fieldName: string, onChange: OnChangeEvent): IReactionDisposer {
        let lastValue = this.values[fieldName];
        const fc = reaction(
            () => this.values[fieldName],
            (value, reaction) => {
                // try to debounce
                if (value === lastValue) return;
                // special handling for array values (may need to make it even more complex for objects)
                if (Array.isArray(value) && Array.isArray(lastValue) && value.length === lastValue.length) {
                    let different = false;
                    for (let c = 0; c < value.length; c++) {
                        if (lastValue[c] !== value[c]) {
                            different = true;
                            break;
                        }
                    }
                    if (!different) return;
                }

                const field = this.getField(fieldName);
                if (!field) return;
                console.debug(`value for ${fieldName} changed from ${lastValue} to ${value}`);
                lastValue = value;
                onChange(this, field, value);
            }
        );

        return fc;
    }

    /**
     * bind listener to field value change (using mobx)
     */
    bindOnChange(fieldName: string, onChange: OnChangeEvent): Form {
        const fc = this.bindOnFieldValueChange(fieldName, onChange);
        this.disposers.push(fc);

        return this;
    }

    getField(fieldName: string): api.FormField | undefined {
        return this.fieldMap[fieldName];
    }

    getError(field: api.FormField): string | undefined {
        return field.name ? this.errors[field.name] : undefined;
    }

    getValue(fieldOrFieldName: api.FormField | string): any {
        const field = typeof fieldOrFieldName === "string" ? this.getField(fieldOrFieldName) : fieldOrFieldName;

        const apiName = field?.apiName ?? field?.name;
        if (!apiName) return undefined;
        return this.values[apiName];
    }

    @action
    setValue(field: api.FormField, value: any) {
        // console.log('setValue', field.name, value);

        const values = this.values;
        const apiName = field.apiName ?? field.name;

        if (!apiName || !(apiName in values)) {
            console.log(`not a valid field ${field.name}`);
            return;
        }

        if (values[apiName] === value) return;

        values[apiName] = value;

        // move to a listener bound to mobx?
        // ...
        let error = this.validateField(field, value);
        this.setError(field, error);

        console.log('setValue', this.context, toJS(values), toJS(Default.state.get(this._context)));
    }

    @action
    assignValues(src: object) {
        const values = this.values;
        Object.keys(src).forEach((name) => {
            if (!(name in values)) {
                // ignore mismatches
                return;
            }
            const value = src[name];
            values[name] = value;
        });
    }

    @action
    resetValues() {
        const values = this.values;

        this.processFields(field => {
            const apiName = field.apiName ?? field.name;
            if (!apiName) return;
            values[apiName] = field.defaultValue;
            this.errors[apiName] = undefined;
        });
    }

    replacePlaceHolders(url?: string | null, valueTokenValue?: any) {
        if (!url) return url;

        let result = url;

        // local only #{{...}}
        const subst1 = result.match(/(#{{[^}]+}})/g) as string[];
        if (subst1) {
            for (const s of subst1) {
                const fieldName = s.substring(3, s.length - 2);
                const value = this.getValue(fieldName);
                if (!value) {
                    continue;
                }
                result = result.replace(s, value.toString());
            }
        }

        // other {{...}}
        const subst2 = result.match(/({{[^}]+}})/g) as string[];
        if (subst2) {
            for (const s of subst2) {
                if (s === "{{value}}") {
                    // for backwards compatibility
                    if (!valueTokenValue) {
                        continue;
                    }
                    result = result.replace(s, valueTokenValue.toString());
                } else {
                    const fieldName = s.substring(2, s.length - 2);
                    const value = this.getValue(fieldName);
                    if (!value) {
                        continue;
                    }
                    result = result.replace(s, value.toString());
                }
            }
        }

        return result;
    }

    getLinkUrl(field: api.FormField): string | null {
        const { options } = field;
        const { linkUrl } = options || {};

        return this.replacePlaceHolders(linkUrl, this.getValue(field)) ?? null;
    }

    @action
    setError(field: api.FormField, error?: string) {
        const apiName = field.apiName ?? field.name;
        if (apiName) this.errors[apiName] = error;
    }

    validate(...fields: string[]): boolean {
        let error = false;
        if (fields === null || fields.length === 0) fields = Object.keys(this.fieldMap);

        fields.forEach((name) => {
            const field = this.fieldMap[name];
            const value = this.getValue(field);
            const msg = this.validateField(field, value);
            if (msg) {
                this.setError(field, msg);
                error = true;
            }
        });

        return !error;
    }

    evaluateRequired(...condition: string[]): boolean {
        if (condition === null || condition.length === 0) return true;

        for (var c = 0; c < condition.length; c++) {
            const name = condition[c];

            const parsed = expr.parse(name);
            if (parsed.isFinal) {
                // constant value
                if (parsed.result) continue;
                return false;
            }

            const field = this.getField(parsed.expression);
            if (field) {
                parsed.result = this.getValue(field);
                var error = this.validateField(field, parsed.result);
                if (error) {
                    console.debug(`${name}: is a field with value "${parsed.result}" validate returned "${error}", treat as undefined value`);
                    parsed.result = undefined;
                    return false;
                }

                if (parsed.result === undefined || parsed.result === null) {
                    console.log("missing");
                    return false;
                }

            } else {
                console.error("unexpected expression for a required field");
                return false;
            }
        }

        return true;
    }

    evaluate(...condition: string[]): boolean {
        if (condition === null || condition.length === 0) return true;

        for (var c = 0; c < condition.length; c++) {
            const name = condition[c];

            const parsed = expr.parse(name);
            if (parsed.isFinal) {
                // constant value
                if (parsed.result) continue;
                return false;
            }

            const field = this.getField(parsed.expression);
            if (field) {
                // form field
                parsed.result = this.getValue(field);
                var error = this.validateField(field, parsed.result);
                if (error) {
                    console.debug(`${name}: is a field with value "${parsed.result}" validate returned "${error}", treat as undefined value`);
                    parsed.result = undefined;
                }
            } else {
                // any other state?
                parsed.result = Default.state.get(parsed.expression, this._context);
                console.debug(`${parsed.expression}: NOT a form field with value "${parsed.result}"`);
            }

            if (!expr.processResult(parsed)) return false;
        }

        return true;
    }

    validateField(field: api.FormField, value: any): string | undefined {
        let error: string | undefined = undefined;
        if (field.isRequired && (value === null || value === undefined || value.toString().length < 1)) {
            error = `Required`;
        } else if (value || value === 0) {
            // hack to handle masked fields (doesn't work for email)
            if (typeof value === "string" && value.indexOf("\u2000") >= 0) {
                error = "Invalid";
            }

            if (field.type === TYPE.EMAIL) {
                if (!/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i.test(value)) {
                    error = "Invalid";
                }
            }
        }

        return error;
    }

    isDisabled(ui: api.UIElement): boolean {
        if (ui.enable) {
            // console.log(`evaluate isDisabled for ${ui.name}: ${ui.enable}`);
            if (!this.evaluate(...ui.enable)) {
                // console.log(">> resulted in disabled", ui.enable, this.getValues());
                return true;
            }
        }

        return false;
    }

    isHidden(ui: api.UIElement): boolean {
        if (!ui.visible) return false;

        var result = this.evaluate(...ui.visible);
        console.debug(`evaluate isHidden for ${ui.name}: ${ui.visible} = ${result}`);
        return !result;
    }

    bindAction(action: string, funct: Function): Form {
        Default.actions.set(action, funct, this._context);
        return this;
    }

    executeAsync(actionOrActionName: Action): Promise<any> | null {
        console.log(`Form::executeAsync: ${actionOrActionName}`);

        if (this.isDisabled(actionOrActionName)) return null;

        let action = actionOrActionName.action ?? actionOrActionName.name;
        if (!action) return null;

        const values = this.values;

        if (typeof action !== "string") {
            return App().executeAsync(action, this._context);
        }

        if (action.startsWith("#")) {
            switch (actionOrActionName.name) {
                case "#design":
                    console.log("toggle design");
                    this.isDesigning = true;
                    return Promise.resolve();

                default:
                    // probably not needed but....
                    const funct = Default.actions.get(action, this._context);
                    if (funct) {
                        funct();
                        return Promise.resolve();
                    }
                    break;
            }

            return null;
        }

        if (URI.isUri(action)) {
            // try to parse url
            var url = new URI(action);
            var path = url.path;
            switch (url.scheme) {
                case "action:":
                    console.log("execute form action");
                    return App().dataFormActionAsync(path, actionOrActionName, values);
            }
        } else if (this.url) {
            // action for the form
            return App().dataFormActionAsync(this.url, actionOrActionName, values);
        }

        let actionStr = action as string;
        Object.keys(values).forEach(prop => {
            const value = values[prop];
            if (!value) return;

            actionStr = actionStr.replaceAll(`{{${prop}}}`, value);
        });

        return App().executeAsync(actionStr, this._context);
    }

    updateCriteria(criteria?: Condition[] | null): Condition[] {
        if (!criteria) return [];

        return criteria.map((x) => {
            // naive hack for now
            if (typeof x.value === "string" && (x.value.startsWith("#{{") || x.value.startsWith("{{")) && x.value.endsWith("}}")) {
                const fieldName = x.value.substring(x.value.startsWith("#{{") ? 3 : 2, x.value.length - 2);
                const fieldValue = this.getValue(fieldName);

                if (fieldValue) {
                    return {
                        ...x,
                        value: fieldValue
                    };
                }
            }

            return x;
        });
    }

    getContainerBreakpoint(maxContainerBreakpoint: api.ScreenBreakpoint): api.ScreenBreakpoint {
        const containerBreakpointIndex = Breakpoints.indexOf(maxContainerBreakpoint);

        if (!this.layouts) {
            // no layouts, use md (for container) so the form is small (by default)
            return Breakpoints.indexOf(api.ScreenBreakpoint.Md) < containerBreakpointIndex ? api.ScreenBreakpoint.Md : maxContainerBreakpoint;
        }

        const availableBreakpointIndice: number[] = [];
        if (this.layouts?.xs) availableBreakpointIndice.push(0);
        if (this.layouts?.sm) availableBreakpointIndice.push(1);
        if (this.layouts?.md) availableBreakpointIndice.push(2);
        if (this.layouts?.lg) availableBreakpointIndice.push(3);
        if (this.layouts?.xl) availableBreakpointIndice.push(4);

        const maxAvailableLayoutBreakpoint = Math.max(...availableBreakpointIndice);
        return maxAvailableLayoutBreakpoint < containerBreakpointIndex ? (Breakpoints[maxAvailableLayoutBreakpoint + 1]) : maxContainerBreakpoint;
    }

    getLayout(maxBreakpoint: api.ScreenBreakpoint): api.FormLayout {
        const layouts = this.layouts;
        const indexedBreakpoints = [
            api.ScreenBreakpoint.Xs,
            api.ScreenBreakpoint.Sm,
            api.ScreenBreakpoint.Md,
            api.ScreenBreakpoint.Lg,
            api.ScreenBreakpoint.Xl
        ];

        const maxIndex = Breakpoints.indexOf(maxBreakpoint);
        const breakpoint = indexedBreakpoints[maxIndex];

        if (layouts) {
            // find lte that is defined
            const sorted = [layouts.xs, layouts.sm, layouts.md, layouts.lg, layouts.xl];
            for (let c = maxIndex; c >= 0; c--) {
                const match = sorted[c];
                if (match) {
                    return {
                        ...match,
                        breakpoint
                    };
                }
            }
        }

        // default to stack
        return {
            t: "FormLayout",
            type: undefined,
            breakpoint: breakpoint
        };
    }

    getObjectTypeForReferenceField(options: api.ReferenceFieldOptions) {
        if (!options?.objectType || options.objectType.indexOf("{{") < 0) {
            return options.objectType;
        }

        let objectType = options.objectType;
        const subst = objectType.match(/({{[^}]+}})/g) as string[];
        if (!subst) {
            console.error("invalid expression");
            return objectType;
        }

        for (const s of subst) {
            const fieldName = s.substring(2, s.length - 2);
            const fieldValue = this.getValue(fieldName);
            if (!fieldValue) {
                console.error(`couldn't evaluate: ${fieldName}`);
                return options.objectType;
            }

            objectType = objectType.replaceAll(s, fieldValue.toString());
        }

        return objectType;
    }
}
