import { Button, ButtonGroup } from "@mui/material";
import moment from "moment";
import { MouseEvent } from "react";

import * as api from "@crochik/pi-api";

interface IRenderActionsProps {
    disabled?: boolean;
    appointment?: api.Appointment;
    options?: api.ReferenceFieldOptions;
    onLaunchScheduler: () => void;
    onCancelAppointment: () => void;
    leadId?: string;
}

export function RenderActions({ options, disabled, appointment, leadId, onLaunchScheduler, onCancelAppointment }: IRenderActionsProps) {
    const actionButton = function (action: api.FormAction) {
        const onAction = (action?: string | null) => (e: MouseEvent) => {
            e.stopPropagation();

            switch (action) {
                case "#reset":
                    onCancelAppointment();
                    break;
                case "#new":
                    onLaunchScheduler();
                    break;
                default:
                    alert(action);
                    break;
            }
        };

        const inPast = appointment && moment().isAfter(moment(appointment.start));

        switch (action.action) {
            case "#new":
                if (!leadId) return;
                return (
                    <Button key={action.name} variant="outlined" onClick={onAction(action.action)}>
                        <span>{appointment && appointment.isActive && !inPast ? "Reschedule" : "Schedule"}</span>
                    </Button>
                );
            case "#reset":
                if (!appointment || !appointment.isActive || inPast) {
                    return;
                }
                return (
                    <Button key={action.name} variant="outlined" onClick={onAction(action.action)}>
                        <span>Cancel</span>
                    </Button>
                );
            default:
                return (
                    <Button key={action.name} variant="outlined" onClick={onAction(action.action)}>
                        <span>{action.label ?? action.name}</span>
                    </Button>
                );
        }
    };

    if (!options || !options.actions) return null;

    return (
        <ButtonGroup orientation="horizontal" disabled={disabled}>
            {options.actions.map((x) => actionButton(x))}
        </ButtonGroup>
    );
}
