import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Backdrop, Button, Fab, IconButton, SelectChangeEvent } from "@mui/material";
import { css, StyleSheet } from "aphrodite";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BreakpointLayouts, FormField, GridFormLayout, ScreenBreakpoint } from "@crochik/pi-api";
import { useForm } from "src/pi/hooks/useForm";
import App from "src/pi/application/App";
import { TYPE } from "src/pi/context/IForm";
import DataService from "src/pi/services/DataService";
import { Form } from "src/pi/ui/Form";
import { DraggableFormCellItem } from "../FormCell/DragAndDrop/DraggableFormCell";
import { Row } from "../FormCell/DragAndDrop/DraggableItemTypes";
import { HiddenFieldSidebar } from "../FormCell/DragAndDrop/HiddenFieldSidebar";
import { CustomizeFormLayout } from "../FormLayout/CustomizeFormLayout";
import { BreakpointSelector } from "./BreakpointSelector";
import { FormLayoutSaver } from "./FormLayoutSaver";
import { URI } from "../../../../api/URI";
import { parseResponseError } from "../../../../api/Client";

import * as api from "@crochik/pi-api";

interface Props {
    initialBreakpoint: ScreenBreakpoint;
    isVisible?: (field: FormField) => boolean;
    onAdd?: () => void;
    onEdit?: (field: FormField) => void;

    onRender?(field: api.FormField): ReactNode;

    onClose?(layouts: BreakpointLayouts): void;
}

const toolbarHeight = 80;
const sidebarWidth = 360;
const formPadding = 32;

interface DeviceSize {
    breakpoint: ScreenBreakpoint;
    maxWidth: number;
    label: string;
}

const deviceSizes: DeviceSize[] = [
    { breakpoint: ScreenBreakpoint.Md, maxWidth: 1200, label: "Desktop (Md<1200)" },
    { breakpoint: ScreenBreakpoint.Sm, maxWidth: 900, label: "Tablet (Sm<900)" },
    { breakpoint: ScreenBreakpoint.Xs, maxWidth: 600, label: "Phone (Xs<600)" }
];

export function FormBuilder(props: Props) {
    const { initialBreakpoint, isVisible, onAdd, onEdit, onRender, onClose } = props;
    const myForm = useForm();
    const screenWidth = App().innerWidth;

    const [deviceSize, setDeviceSize] = useState(getInitialDeviceSize());
    const [isSaveDialogVisible, showSaveDialog] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [form, setForm] = useState(myForm);
    const [profileId, setProfileId] = useState<string>();
    const [layouts, setLayouts] = useState<BreakpointLayouts>(form.layouts || {});
    const [rows, setRows] = useState<Row[]>([]);
    const [hiddenFields, setHiddenFields] = useState<api.FormField[]>([]);
    const [loadError, setLoadError] = useState<string>();

    const initFromLayout = useCallback(
        (layout: any) => {
            const visible: { [name: string]: boolean } = {};

            const newRows = layout?.rows?.map((row) => {
                const fullFields = row.fields
                    .map((field) => {
                        return form.fields?.find((x) => x.name === field.name);
                    })
                    .filter((field) => {
                        return !!field && field.type !== TYPE.HIDDEN;
                    });

                fullFields.forEach(field => {
                    visible[field.name] = true;
                });

                return { ...row, fields: fullFields };
            }).filter(x => x.fields.length > 0) ?? [];

            setRows(newRows);

            const hiddenFields = form?.fields?.filter(x => x.type !== TYPE.HIDDEN && !visible[x.name!])?.sort((a, b) => (a.label ?? a.name ?? "").localeCompare(b.label ?? b.name ?? ""));
            setHiddenFields(hiddenFields ?? []);
        },
        [form?.fields]
    );

    const resetLayout = useCallback(() => {
        const hiddenFields: FormField[] = [];
        const newRows = form?.fields?.filter((field) => {
            if (isVisible && !isVisible(field)) {
                hiddenFields.push(field);
                return false;
            }
            return field.type !== TYPE.HIDDEN;
        }).map((x) => {
            return { fields: [x] };
        });

        console.log('resetLayout', deviceSize,  newRows);

        setLayouts({
            ...layouts,
            [deviceSize.breakpoint]: { type: "Grid", breakpoint: deviceSize.breakpoint, rows: newRows ?? [] }
        });

        setRows(newRows || []);
        setHiddenFields(hiddenFields.sort((a, b) => (a.label ?? a.name ?? "").localeCompare(b.label ?? b.name ?? "")));
    }, [form?.fields, deviceSize, layouts, isVisible]);

    useEffect(() => {
        if (!form?.fields?.length) return;

        if (deviceSize.breakpoint in layouts) {
            const layout = layouts[deviceSize.breakpoint];
            initFromLayout(layout);
        } else {
            resetLayout();
        }
    }, [form.fields, layouts, deviceSize, initFromLayout, resetLayout]);

    useEffect(() => {
        // form.hash changed (fields changed)
        if (!form?.fields?.length) return;

        if (form.layouts && deviceSize.breakpoint in form.layouts) {
            console.log("load layout");
            setLayouts(form.layouts);
            const layout = form.layouts[deviceSize.breakpoint];
            initFromLayout(layout);
        }

    }, [form.hash]);

    useEffect(() => {
        // rows changed, update form layouts
        const currLayout = { type: "Grid", breakpoint: deviceSize.breakpoint, rows };
        form.layouts = {
            ...form.layouts,
            [deviceSize.breakpoint]: currLayout
        };

    }, [rows]);

    useEffect(() => {
        if (!profileId) {
            setForm(myForm);
            return;
        }

        // load
        setLoading(true);

        const uri = new URI(`dataform:${myForm.url}`);
        const pathAndQuery = uri.getPathAndQuery(`/Profile(${profileId})`);
        console.log("load form for profile", profileId, myForm.url, pathAndQuery);

        DataService()
            .dataFormAsync(pathAndQuery)
            .then((f) => {
                if (!f) {
                    console.error("failed to load form");
                    setLoading(false);
                    return;
                }
                const newForm = Form.create(f, pathAndQuery);
                setForm(newForm);
                // if (newForm.layouts) {
                setLayouts(newForm.layouts ?? {});
                // } else {
                //     resetLayout();
                // }
                setLoading(false);
            })
            .catch(e => {
                console.error("loading form for profile", e);
                parseResponseError(e).then(error => {
                    setLoadError(error);
                    setLoading(false);
                });
            });

    }, [profileId, myForm]);

    function getInitialDeviceSize() {
        if (initialBreakpoint === ScreenBreakpoint.Xs) {
            return deviceSizes[2];
        } else if (initialBreakpoint === ScreenBreakpoint.Sm) {
            return deviceSizes[1];
        }
        return deviceSizes[0];
    }

    const updateLayout = useCallback((newBreakpoint?: ScreenBreakpoint) => {
        const newLayout : GridFormLayout = {
            t: "GridFormLayout",
            type: "Grid",
            breakpoint: deviceSize.breakpoint,
            rows
        };
        let newLayouts : BreakpointLayouts = { ...layouts, [deviceSize.breakpoint]: newLayout };
        if (!!newBreakpoint && !newLayouts[newBreakpoint]) {
            const copy : GridFormLayout = {
                t: "GridFormLayout",
                type: "Grid",
                breakpoint: newBreakpoint,
                rows
            };
            newLayouts[newBreakpoint] = copy;
        }
        setLayouts(newLayouts);
        return newLayouts;
    }, [deviceSize.breakpoint, layouts, rows]);

    const maxWidth = useMemo(() => deviceSize.maxWidth, [deviceSize]);

    const isScreenTooSmall = useMemo(() => {
        return screenWidth - sidebarWidth - formPadding * 2 < maxWidth;
    }, [screenWidth, maxWidth]);

    const onChangeMaxWidth = (event: SelectChangeEvent<number>) => {
        const newDeviceSize = deviceSizes.find((x) => x.maxWidth === event.target.value);
        if (!!newDeviceSize) {
            setDeviceSize(newDeviceSize);
            updateLayout(newDeviceSize.breakpoint);
        }
    };

    const deleteItem = (item: DraggableFormCellItem, tmpRows: Row[]) => {
        if (tmpRows[item.currentRowId].fields.length === 1) {
            tmpRows.splice(item.currentRowId, 1);
        } else {
            const tmpFields = [...tmpRows[item.currentRowId].fields];
            tmpFields.splice(item.currentFieldId, 1);
            tmpRows[item.currentRowId].fields = tmpFields;
        }
        return tmpRows;
    };

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onHide = (item: DraggableFormCellItem) => {
        setRows((prevRows) => {
            let tmpRows = [...prevRows];
            tmpRows = deleteItem(item, tmpRows);
            return tmpRows;
        });

        setHiddenFields((prevHiddenFields) => {
            let tmpHiddenFields = [...prevHiddenFields];
            tmpHiddenFields.push(item.field);
            return tmpHiddenFields.sort((a, b) => (a.label ?? a.name ?? "").localeCompare(b.label ?? b.name ?? ""));
        });
    };

    const removeHiddenField = (item: DraggableFormCellItem) => {
        setHiddenFields((prevHiddenFields) => {
            let tmpHiddenFields = [...prevHiddenFields];
            const index = tmpHiddenFields.indexOf(item.field);
            if (index >= 0) {
                tmpHiddenFields.splice(index, 1);
            }
            return tmpHiddenFields;
        });
    };

    const onDropExisting = useCallback(
        (item: DraggableFormCellItem) => {
            console.log(`Move field to exisiting row: (${item.currentRowId}/${item.currentFieldId}) -> (${item.newRowId}/${item.newFieldId})`);
            setRows((prevRows) => {
                let tmpRows = [...prevRows];
                // Add field to existing row in correct place
                if (item.newFieldId !== undefined && item.newRowId !== undefined) {
                    // reorder fields
                    if (item.currentRowId === item.newRowId && !item.hidden) {
                        const rowId = item.currentRowId;
                        const fields = prevRows[rowId].fields;
                        const reorderedFields = reorder(fields, item.currentFieldId, item.newFieldId);
                        tmpRows[rowId].fields = reorderedFields;
                    } else {
                        const addedFields = [...prevRows[item.newRowId].fields];
                        addedFields.splice(item.newFieldId, 0, item.field);
                        tmpRows[item.newRowId].fields = addedFields;
                        if (!item.hidden) {
                            tmpRows = deleteItem(item, tmpRows);
                        }
                    }
                }
                return tmpRows;
            });

            if (item.hidden) {
                removeHiddenField(item);
            }
        },
        [setRows]
    );

    const onDropNew = useCallback(
        (item: DraggableFormCellItem) => {
            console.log(`Move field to new row: (${item.currentRowId}/${item.currentFieldId}) -> (${item.newRowId}/${item.newFieldId || 0})`);
            setRows((prevRows) => {
                let tmpRows = [...prevRows];
                if (item.newRowId !== undefined) {
                    tmpRows.splice(item.newRowId + 1, 0, { fields: [item.field] });
                    if (!item.hidden) {
                        const itemToDelete: DraggableFormCellItem =
                            item.newRowId >= item.currentRowId ? item : {
                                ...item,
                                currentRowId: item.currentRowId + 1
                            };
                        tmpRows = deleteItem(itemToDelete, tmpRows);
                    }
                }
                return tmpRows;
            });
            if (item.hidden) {
                removeHiddenField(item);
            }
        },
        [setRows]
    );

    const onReorder: OnDragEndResponder = (result) => {
        if (!result.destination) {
            return;
        }

        setRows((prevRows) => {
            let tmpRows = [...prevRows];
            if (!!result?.destination) {
                tmpRows = reorder(tmpRows, result.source.index, result.destination.index);
            }
            return tmpRows;
        });
    };

    const onClearLayout = (size: string) => (event: React.MouseEvent) => {
        event.stopPropagation();

        const newLayouts = { ...layouts };
        delete newLayouts[size];
        setLayouts(newLayouts);
    };

    const saveLayoutClick = () => {
        updateLayout();
        showSaveDialog(true);
    };

    const closeSaveDialog = (saved: boolean) => {
        showSaveDialog(false);
        if (saved) onCloseDesigner();
    };

    const onCloseDesigner = useCallback(() => {
        onClose?.(updateLayout());
    }, [onClose, updateLayout]);

    const onProfileIdChange = (event: SelectChangeEvent<string>) => {
        setProfileId(event.target.value);
    };

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <div className={css(styles.container)}>
                    <div className={css(styles.toolbar)}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <BreakpointSelector value={maxWidth} onChange={onChangeMaxWidth} layouts={layouts}
                                                onClear={onClearLayout} />
                            <Button sx={{ height: 45, marginLeft: 1 }} variant="outlined"
                                    onClick={resetLayout}>Reset</Button>
                        </div>
                        <div className={css(styles.title)}>{form.name}</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {/*{form.url && <ProfileSelector value={profileId} onChange={onProfileIdChange} />}*/}
                            {form.url && <Button sx={{ height: 45, marginLeft: 1 }} variant="outlined"
                                                 onClick={saveLayoutClick}>Save</Button>}
                            {onClose && (
                                <IconButton onClick={onCloseDesigner}>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>

                    {
                        loadError ?
                            <Alert severity="error">{loadError}</Alert> :
                            <div className={css(styles.row)}>
                                <div className={css(styles.hiddenFields)}>
                                    <HiddenFieldSidebar
                                        fields={hiddenFields}
                                        form={form}
                                        onDrop={onHide}
                                        onProperties={onEdit}
                                        width={sidebarWidth}
                                        onRender={onRender}
                                    />
                                </div>
                                <div className={css(styles.content)}>
                                    <CustomizeFormLayout
                                        maxWidth={maxWidth}
                                        onDropExisting={onDropExisting}
                                        onDropNew={onDropNew}
                                        onReorder={onReorder}
                                        onProperties={onEdit}
                                        rows={rows}
                                        formPadding={formPadding}
                                        isScreenTooSmall={isScreenTooSmall}
                                        onRender={onRender}
                                    />
                                    {
                                        onAdd && (
                                            <Fab size="large" color="primary" aria-label="add"
                                                 style={{ position: "absolute", right: "24px", bottom: "24px" }}
                                                 onClick={onAdd}>
                                                <AddIcon />
                                            </Fab>
                                        )
                                    }
                                </div>
                            </div>
                    }
                </div>
            </DndProvider>
            {isLoading &&
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading} />}
            {isSaveDialogVisible && <FormLayoutSaver onClose={closeSaveDialog} layouts={layouts} />}
        </>
    );
}

export const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative"
    },
    content: {
        backgroundColor: "#eee",
        display: "flex",
        justifyContent: "center",
        height: `100%`,
        width: `calc(100% - ${sidebarWidth}px)`
    },
    hiddenFields: {
        height: `100%`,
        borderRight: "1px solid #ccc"
    },
    row: {
        display: "flex",
        flexGrow: 1,
        height: `calc(100% - ${toolbarHeight}px)`,
        position: "relative"
    },
    toolbar: {
        justifyContent: "space-between",
        padding: 16,
        display: "flex",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#ccc",
        backgroundColor: "white",
        height: toolbarHeight
    },
    title: {
        margin: 0,
        fontSize: 22
    }
});
