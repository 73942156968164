import { Button, ButtonGroup } from "@mui/material";

import * as api from "@crochik/pi-api";

interface IRenderActionsProps {
    disabled?: boolean;
    options?: api.ReferenceFieldOptions;
    onAction: (action: api.FormAction) => void;
}

export function Actions({ options, disabled, onAction }: IRenderActionsProps) {
    const actionButton = function (action: api.FormAction) {
        const onActionClick = () => onAction(action);

        switch (action.action) {
            case "#new":
                return (
                    <Button key={action.name} variant="outlined" onClick={onActionClick}>
                        <span>{action.label ?? "New"}</span>
                    </Button>
                );
            // case "#reset":
            //     return (
            //         <Button key={action.name} variant="outlined" onClick={onAction(action.action)}>
            //             <span>Cancel</span>
            //         </Button>
            //     );
            default:
                return (
                    <Button key={action.name} variant="outlined" onClick={onActionClick}>
                        <span>{action.label ?? action.name}</span>
                    </Button>
                );
        }
    };

    if (!options || !options.actions) return null;

    return (
        <ButtonGroup orientation="horizontal" disabled={disabled}>
            {options.actions.map((x) => actionButton(x))}
        </ButtonGroup>
    );
}
