import * as api from "@crochik/pi-api";

export const DraggableItemTypes = {
    ROW: "row",
    FIELD: "field",
};

export interface Row {
    fields: api.FormField[];
}
