import { Alert } from "@mui/material";
import { css, StyleSheet } from "aphrodite";
import { useState } from "react";
import * as api from "@crochik/pi-api";
import App from "src/pi/application/App";
import { Action } from "src/pi/context/IForm";
import DataService, { IDataFormActionRequest } from "src/pi/services/DataService";
import { Form } from "src/pi/ui/Form";
import { FormDialog } from "../../FormDialog";
import { FieldProps } from "../FieldProps";
import { StandardReferenceField } from "../ReferenceField";
import { Actions } from "./Actions";
import { UnlayerDialog } from "./UnlayerDialog";
import { URI } from "../../../../api/URI";

interface IProps extends FieldProps {
    form?: Form;
}

export function UnlayerTemplateField(props: IProps) {
    const { field, disabled, value, form, onChange } = props;
    const [isEditing, setEditing] = useState<boolean>(false);

    const [actionForm, setActionForm] = useState<Form>();
    const options = field.options as api.ReferenceFieldOptions ?? {};
    const initValues: { [key: string]: object } = {};
    if (options.criteria) {
        options.criteria
            .filter(x => x.operator === api.Operator.Eq && x.fieldName && x.value)
            .forEach(x => {
                initValues[x.fieldName!] = x.value!.toString()
            });
    }

    if (!form) {
        return <Alert severity="error">Missing Form</Alert>;
    }

    const onDone = (id: string | undefined) => {
        if (id) {
            onChange?.(field, id);
        }

        setEditing(false);
    };

    const onEdit = () => {
        setEditing(true);
    };

    const onCloseActionFormAsync = () => {
        setActionForm(undefined);
        return Promise.resolve();
    };

    const onAction = (action: api.FormAction) => {
        if (typeof action.action !== "string" || !action.action) {
            console.error("no action");
            return;
        }

        switch (action.action) {
            case "#new":
                onEdit();
                return;
        }

        let actionUrl = action.action;
        if (!URI.isUri(actionUrl)) {
            actionUrl = `dataForm:/sendgrid/v1/Unlayer({{value}})/${actionUrl}`;
        }

        if (!value) {
            alert("Template Required");
            return;
        }

        actionUrl = actionUrl.replace("{{value}}", value);
        const uri = new URI(actionUrl);

        App()
            .loadFormAsync(uri.getPathAndQuery())
            .then((form) => {
                setActionForm(form);
            });
    };

    const onFormActionAsync = async (action: Action) => {
        if (!actionForm || !actionForm.url) {
            console.error("missing form");
            return;
        }
        const request: IDataFormActionRequest = {
            action: typeof action.action === "string" ? action.action : "",
            parameters: actionForm.values,
            selectedIds: [],
        };

        const result = await DataService().dataFormActionAsync(actionForm.url, request)
        if (!result.success) {
            alert(result.message);
            return;
        }

        // TODO: handle action, nextUrl, ...
        // assumes cancel for now
        // ...

        await onCloseActionFormAsync();
    };

    const label = field.label ?? field.name;

    return (
        <>
            <div className={css(styles.container)}>
                <StandardReferenceField {...props} />
                {!disabled && <Actions options={options} disabled={false} onAction={onAction} />}
            </div>
            {actionForm && <FormDialog form={actionForm} onCloseAsync={onCloseActionFormAsync} onActionAsync={onFormActionAsync} />}
            {isEditing && <UnlayerDialog onDone={onDone} title={label ?? "Template"} id={value} initValues={initValues} />}
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        // paddingTop: 16,
        // paddingBottom: 16,
        // paddingLeft: 12,
        // paddingRight: 12,
        // border: "1px solid rgba(0,0,0,.26)",
        // backgroundColor: "white",
        // // borderRadius: 8,
        marginTop: 0,
        marginBottom: 16,
    },
});
