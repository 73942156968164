import MoneyIcon from "@mui/icons-material/AttachMoney";
import { FormControl, Input, InputBaseComponentProps, InputLabel, Rating } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { NumberFieldOptionsStyle } from "@crochik/pi-api";
import * as api from "@crochik/pi-api";
import { InputTextField } from "./InputTextField";

function RatingInput(props: InputBaseComponentProps) {
    const { value, disabled } = props;
    return (
        <Rating
            value={value}
            // onChange={onChange}
            disabled={disabled}
        />
    );
}

export function PriceInput(props: InputBaseComponentProps) {
    const { value, disabled } = props;
    return <Rating value={value as number} size="small" icon={<MoneyIcon color="primary" />} emptyIcon={<MoneyIcon color="disabled" />} disabled={disabled} />;
}

export class NumberField extends InputTextField {
    protected onChange = (event: any) => {
        let value = event.target.value;
        if (value?.trim() === "") {
            value = null;
        }

        const { field } = this.props;
        const options = field.options as api.NumberFieldOptions;
        try {
            if ((value || value === 0) && options && options.decimalPlaces !== null && options.decimalPlaces !== undefined) {
                if (!options.decimalPlaces) {
                    value = parseInt(value);
                }
            } else if (value) {
                value = Number.parseFloat(value);
            }
        } catch {
            console.error('failed to parse number');
            value = null;
        }

        if (this.props.onChange) {
            this.props.onChange(this.props.field, value);
        }
    };

    renderRating(type: NumberFieldOptionsStyle) {
        const { style, field, value } = this.props;

        var props = {
            disabled: this.props.disabled,
            error: this.props.error ? true : false,
            onChange: this.onChange,
            fullWidth: true,
            // margin: 'normal',
        };

        const calcStyle = {
            ...style,
            display: "flex",
            paddingTop: "16px",
            paddingBottom: "16px",
            border: "1px solid rgba(0,0,0,.26)",
            borderRadius: 4,
            marginBottom: 8,
            marginTop: 16,
        };

        const labelProps = {
            required: field.isRequired,
            error: this.props.error ? true : false,
            htmlFor: field.name!,
            shrink: true,
        };

        const currentValue = !value || Number.isNaN(value) ? 0 : value;

        return (
            <div style={calcStyle}>
                <FormControl {...props} variant="outlined">
                    <InputLabel {...labelProps}>{field.label ?? field.name!}</InputLabel>
                    <Input
                        style={{ paddingLeft: 12 }}
                        name={field.name!}
                        disableUnderline
                        inputProps={{
                            id: field.name!,
                            value: currentValue,
                        }}
                        inputComponent={type === NumberFieldOptionsStyle.Rating ? RatingInput : PriceInput}
                    />
                </FormControl>
            </div>
        );
    }

    render() {
        const field = this.props.field;
        const options = field.options as api.NumberFieldOptions;

        if (options?.style) {
            switch (options.style) {
                case NumberFieldOptionsStyle.Price:
                case NumberFieldOptionsStyle.Rating:
                    return this.renderRating(options.style);
            }
        }

        var props = {
            ...this.calcProps(),
            fullWidth: true,
            type: "number",
        };

        return <MuiTextField {...props} variant="filled" />;
    }
}
