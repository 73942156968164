import { FormControl, Popover } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { IReferenceValue } from "../../../../services/DataService";
import { AutocompleteBase } from "../Autocomplete/AutocompleteBase";
import { AutocompleteReadonly } from "../Autocomplete/AutocompleteReadonly";
import { FieldProps } from "../FieldProps";
import * as api from "@crochik/pi-api";

interface IProps extends FieldProps {
    displayInline?: boolean;
    autoOpen?: boolean;
}

export function MultiSelectFieldF(props: IProps) {
    const { field, style, value, onChange, displayInline, autoOpen } = props;
    const [searchText, setSearchText] = React.useState("");
    const [isOpen, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [pendingValue, setPendingValue] = useState<string[]>(value);

    const rows = useMemo(() => {
        const options = field.options as api.SelectFieldOptions;

        if (!options) {
            console.error("SelectField", "Missing required options");
            return [];
        }

        const { items } = options;

        const list: IReferenceValue[] = [];
        if (items) {
            for (var option of Object.keys(items)) {
                const refValue: IReferenceValue = {
                    id: option,
                    value: items[option]
                };
                list.push(refValue);
            }
        }

        if (options.allowUnknown && Array.isArray(value)) {
            list.push(
                ...value.filter(x => !items || !(x in items)).map(x => {
                    return {
                        id: x,
                        value: x
                    };
                }));
        }

        return list;

    }, [field.options, value]);

    const selectedMap = useMemo(() => {
        const map: { [id: string]: IReferenceValue } = {};
        rows?.forEach(x => {
            map[x.id] = x;
        });
        return map;
    }, [rows]);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((e) => setSearchText(e.target.value), []);

    const filteredRows = useMemo(() => {
        const options = field.options as api.SelectFieldOptions;

        if (!!searchText?.length) {
            const filtered =  rows.filter((row) => row.value.toLowerCase().includes(searchText.toLowerCase()));
            if (options.allowUnknown && !filtered.find(x=>x.id===searchText)) {
                filtered.push({
                    id: searchText,
                    value: searchText,
                });
            }
            return filtered;
        }
        return rows;
    }, [rows, searchText]);

    const onValueChange = useCallback((newValue: string[]) => {
        setPendingValue(newValue);
        if (!displayInline) return;
        onChange?.(field, newValue);
    }, [field, onChange]);

    const onClear = useCallback(() => onChange?.(field, []), []);

    const onOpen = () => {
        setOpen(true);
    };

    const onClosePopover = () => {
        if (!displayInline) {
            onChange?.(field, pendingValue);
        }
        setOpen(false);
    };

    // have to wait for the anchor to be set :(
    React.useEffect(() => {
        if (anchorRef.current && autoOpen) {
            setOpen(true);
        }
    }, [anchorRef.current]);

    const autocompleteBaseProps = {
        searchStr: searchText,
        selectedMap,
        rows: filteredRows,
        value,
        onInputChange,
        onValueChange,
        label: field.label ?? field.name ?? "",
        onOpen
    };

    if (displayInline) {
        return (
            <AutocompleteBase {...autocompleteBaseProps} />
        );
    }

    return (
        <div
            style={{
                display: "flex",
                paddingTop: 16,
                paddingBottom: 8,
                ...style
            }}
            key={field.name}
            ref={anchorRef}
        >
            <FormControl style={{ width: "100%" }}>
                <div className={css(styles.anchor)}>
                    <AutocompleteReadonly {...autocompleteBaseProps} onClear={onClear} />
                </div>
                {isOpen && anchorRef.current &&
                    <Popover
                        open={isOpen}
                        anchorEl={anchorRef.current}
                        onClose={onClosePopover}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                    >
                        <AutocompleteBase {...autocompleteBaseProps} />
                    </Popover>
                }
            </FormControl>
        </div>
    );
}

const styles = StyleSheet.create({
    anchor: {
        cursor: "pointer"
    }
});


@observer
export class MultiSelectField extends React.Component<IProps> {
    render(): React.ReactNode {
        const props = toJS(this.props);
        return <MultiSelectFieldF {...props} />;
    }
}
