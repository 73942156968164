import { observer } from "mobx-react";
import * as React from "react";
import { useState } from "react";
import { toJS } from "mobx";
import { FieldProps } from "../FieldProps";
import { Form } from "../../../Form";
import { TYPE } from "../../../../context/IForm";
import { factory } from "../Factory";
import * as api from "@crochik/pi-api";

import IconButton from "@mui/material/IconButton";
import { FieldTypeIcon } from "../../FormBody/FormCell/DragAndDrop/FieldTypeIcon";

export interface IProps extends FieldProps {
    form: Form;
    valueField?: api.FormField;
}

function ExpressionFieldF(props: IProps) {
    const { form, field, disabled, valueField, value, onChange } = props;
    const [isExpression, setExpression] = useState(typeof value === "string" && value.indexOf("{{") >= 0);

    const toggle = () => {
        setExpression(!isExpression);
    };

    const expressionFieldProps: FieldProps = {
        field: {
            t: "TextField",
            type: TYPE.TEXT,
            name: field.name,
            label: `${valueField?.label ?? field.label ?? field.name} (expression)`
        },
        error: undefined,
        disabled,
        value,
        onChange
    };

    if (!valueField) { // || valueField.type === TYPE.TEXT
        // if it is a text, no need to toggle
        return factory.render(form, expressionFieldProps.field, expressionFieldProps);
    }

    const valueProps: FieldProps = !isExpression ? {
        field: {
            ...valueField,
            name: field.name,
            label: valueField?.label ?? field.label ?? field.name
        },
        error: undefined,
        disabled,
        value,
        onChange
    } : expressionFieldProps;

    if (valueField.type === TYPE.GENERIC) {
        // if it is a generic field, no need to toggle
        return factory.render(form, valueProps.field, valueProps);
    }

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <IconButton onClick={toggle} style={{ marginRight: "4px", marginTop: "4px", background: "#efefef" }}
                        disabled={disabled}>
                <FieldTypeIcon type={isExpression ? TYPE.EXPRESSION : valueField.type ?? undefined} />
            </IconButton>

            <div style={{ width: "100%" }} key="key">
                {factory.render(form, valueProps.field, valueProps)}
            </div>
        </div>
    );
}

@observer
export class ExpressionField extends React.Component<IProps> {
    render() {
        const { value, field, ...other } = this.props;
        const v = toJS(value);

        return <ExpressionFieldF {...this.props} value={v} />;
    }
}