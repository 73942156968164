import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { css, StyleSheet } from "aphrodite";
import { ReactNode } from "react";
import { useDrop } from "react-dnd";
import { FormField } from "@crochik/pi-api";
import { Form } from "src/pi/ui/Form";
import { DraggableFormCell, DraggableFormCellItem } from "./DraggableFormCell";
import { DraggableItemTypes } from "./DraggableItemTypes";
import * as api from "@crochik/pi-api";

interface Props {
    fields: api.FormField[];
    form: Form;
    onDrop(item: DraggableFormCellItem): void;
    onProperties?: (field: FormField) => void;
    onRender?(field: api.FormField): ReactNode;
    width: number;
}

export function HiddenFieldSidebar(props: Props) {
    const { fields, form, onDrop, width, onProperties, onRender } = props;

    const [{ isOver }, drop] = useDrop<DraggableFormCellItem, any, any>(() => ({
        accept: DraggableItemTypes.FIELD,
        drop: onDrop,
        canDrop: (item: DraggableFormCellItem) => !item.hidden,
        collect: (monitor) => ({
            isOver: !!monitor.isOver() && !!monitor.canDrop(),
        }),
    }));

    return (
        <div className={css(styles.container, isOver && styles.active)} style={{ width }} ref={drop}>
            <div className={css(styles.title)}>
                <div>{"Hidden Fields"}</div>
                <VisibilityOffIcon />
            </div>
            {fields.map((field, index) => {
                return <DraggableFormCell key={`form-cell-${index}`} field={field} form={form} index={index} row={index} hidden={true} onShowInfo={onProperties} onRender={onRender} />;
            })}
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#fff",
        height: "100%",
        padding: 8,
        borderWidth: 2,
        borderStyle: "dashed",
        borderColor: "transparent",
        overflowY: "auto",
    },
    active: {
        borderColor: "#42A5F5",
    },
    title: {
        padding: 8,
        fontSize: 18,
        display: "flex",
        fontWeight: "bold",
        justifyContent: "space-between",
    },
});
