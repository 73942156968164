import CheckedIcon from "@mui/icons-material/DoneOutlined";

import { TYPE } from "../../../context/IForm";
import { DataView } from "../../DataView";

import { Chip, Stack } from "@mui/material";
import { toJS } from "mobx";
import { ReadOnlyFieldValue } from "../FormBody/FormCell/ReadOnlyFormCell";
import { UnknownValue } from "../../ObjectTypeDesigner/UnknownValue";
import * as api from "@crochik/pi-api";

export function renderValue(view: DataView, field: api.FormField, row: any, onFilter?: (value: object) => any) {
    if (!field.name) return "[error: missing field name]";

    var value = row[field.name];

    try {
        switch (field.type) {
            case TYPE.ARRAY:
            case TYPE.CHILDREN:
            case TYPE.DATE:
            case TYPE.DATETIME:
            case TYPE.DICTIONARY:
            case TYPE.LABEL:
            case TYPE.LOCATION:
            case TYPE.LOCATIONDISTANCE:
            case TYPE.NUMBER:
            case TYPE.OBJECT:
            case TYPE.SELECT:
            case TYPE.TEXT:
            case TYPE.TIME:
            case TYPE.URL:
                return <ReadOnlyFieldValue row={row} field={field} value={value} />

            case TYPE.CHECKBOX:
            case "boolean":
                return !value ? "" : <CheckedIcon />;

            case TYPE.TAGS:
                const onClick = (value: string) => (e: React.MouseEvent) => {
                    e.stopPropagation();

                    let filter: string[] = toJS(view.filterForm?.getValue(field) ?? []);

                    const index = filter.indexOf(value);
                    if (index >= 0) {
                        filter.splice(index, 1);
                    } else {
                        filter.push(value);
                    }
                    if (onFilter) onFilter(filter);
                };

                if (Array.isArray(value)) {
                    return (
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            rowGap: "4px",
                            columnGap: "4px",
                            marginTop: 8,
                            marginBottom: 8
                        }}>

                            {value?.map((x) => (
                                <Chip key={x} label={x} variant="filled" size="small" onClick={onClick(x)} />
                            ))}
                        </div>
                    );
                }
                return;

            case TYPE.REFERENCE:
                const fieldName = `${field.name}|Name`;
                if (fieldName in row) return row[fieldName] || "";
                break;

            case TYPE.GENERIC:
                return <UnknownValue value={value}/>;

            default:
                break;
        }
    } catch (e) {
        console.error(e);
    }

    if (typeof(value) === "object") {
        return <UnknownValue value={value} />
    }

    return value ?? "";
}

export function formatDate(field: api.FormField, value: object): string {
    var date: Date;
    if (value instanceof Date) date = value;
    else if (typeof value === "string") date = new Date(value);
    else {
        return `!${value}!`;
    }

    switch (field.type) {
        case TYPE.DATE:
            return date.toLocaleDateString();
        case TYPE.TIME:
            return date.toLocaleTimeString();
        case TYPE.DATETIME:
        default:
            return date.toLocaleString();
    }
}
