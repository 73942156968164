import { observer } from "mobx-react";
import * as React from "react";
import { Form } from "../../../Form";
import { factory } from "../../FormFields/Factory";
import { FieldProps } from "../../FormFields/FieldProps";
import * as api from "@crochik/pi-api";

interface FormFieldProps {
    form: Form;
    field: api.FormField;
    extraProps?: any;
    style?: React.CSSProperties;
    value: any;
}

@observer
export class FieldComponent extends React.Component<FormFieldProps> {
    onChange = (field: api.FormField, value: object) => {
        this.props.form.setValue(field, value);
    };

    onEnterPressed = (field: api.FormField) => {
        const { form } = this.props;

        if (!form.actions || form.actions.length > 1) {
            // check wheter there is a default action (only one primary?)
            // ...
            return false;
        }

        const action = form.actions[0];

        // will execute promise in parallel
        const promise = form.executeAsync(action);
        return !!promise;
    };

    render() {
        const { field, style, extraProps, value } = this.props;

        const fieldProps: FieldProps = {
            ...factory.calculateProps(this.props.form, field, extraProps),
            style,
            onChange: this.onChange,
            onEnterPressed: this.onEnterPressed,
            value,
        };

        return factory.render(this.props.form, field, fieldProps);
    }
}
