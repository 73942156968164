import WarningIcon from "@mui/icons-material/Warning";
import { css, StyleSheet } from "aphrodite";
import { ReactNode, useState } from "react";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { FormField } from "@crochik/pi-api";
import { DraggableFormCellItem } from "../FormCell/DragAndDrop/DraggableFormCell";
import { DraggableFormRow } from "../FormCell/DragAndDrop/DraggableFormRow";
import { Row } from "../FormCell/DragAndDrop/DraggableItemTypes";
import { DropNewFormCell } from "../FormCell/DragAndDrop/DropNewFormCell";
import { FieldInfoDialog } from "../FormCell/DragAndDrop/FieldInfoDialog";
import * as api from "@crochik/pi-api";

interface Props {
    formPadding: number;
    isScreenTooSmall?: boolean;
    maxWidth?: number;
    onDropExisting(item: DraggableFormCellItem): void;
    onDropNew(item: DraggableFormCellItem): void;
    onReorder: OnDragEndResponder;
    onProperties?: (field: FormField) => void;
    onRender?(field: api.FormField): ReactNode;
    rows: Row[];
}

export function CustomizeFormLayout(props: Props) {
    const { formPadding, isScreenTooSmall, maxWidth, onDropExisting, onDropNew, onReorder, rows, onProperties, onRender } = props;
    const [selectedField, setSelectedField] = useState<api.FormField>();
    const onShowInfo = (field: api.FormField) => {
        if (onProperties) {
            onProperties(field);
            return;
        }
        setSelectedField(field)
    };
    const onHideInfo = () => setSelectedField(undefined);

    return (
        <div className={css(styles.form)} style={{ maxWidth: maxWidth || "100%", margin: formPadding }}>
            {isScreenTooSmall && (
                <div className={css(styles.tooSmall)}>
                    <WarningIcon fontSize="small" />
                    <div>{"Current screen width smaller than target screen size"}</div>
                </div>
            )}
            <DragDropContext onDragEnd={onReorder}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {rows.map((row, rowIndex) => (
                                <div className={css(styles.row)} key={`row-${rowIndex}`}>
                                    <DraggableFormRow row={row} rowIndex={rowIndex} onDrop={onDropExisting} onShowInfo={onShowInfo} onRender={onRender} />
                                    <DropNewFormCell rowIndex={rowIndex} onDrop={onDropNew} />
                                </div>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {selectedField && <FieldInfoDialog field={selectedField} onClose={onHideInfo} />}
        </div>
    );
}

const styles = StyleSheet.create({
    form: {
        borderColor: "#EEEEEE",
        backgroundColor: "#fff",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 4,
        overflow: "auto",
        width: "100%",
    },
    tooSmall: {
        alignItems: "center",
        justifyContent: "center",
        fontSize: 12,
        backgroundColor: "#FFD54F",
        display: "flex",
        padding: 8,
    },
    row: {},
});
