import { observer } from "mobx-react";
import * as React from "react";

import { Form } from "../Form";

import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import { Action } from "../../context/IForm";

export type ALIGN = "left" | "center" | "right";
export type VARIANT = "text" | "outlined" | "contained"; // | 'fab' | 'extendedFab' | 'raised'| 'flat'
export type COLOR = "inherit" | "primary" | "secondary";

interface Props {
    action: Action;
    onClick: (action: Action) => any;
    disabled?: boolean;
    variant?: VARIANT;
    color?: COLOR;
    style?: React.CSSProperties;
}

export class ActionButton extends React.Component<Props> {
    onClick = () => this.props.onClick(this.props.action);

    render() {
        return (
            <Button
                variant={this.props.variant ?? "contained"}
                color={this.props.color ?? "inherit"}
                onClick={this.onClick}
                disabled={this.props.disabled}
                style={this.props.style}
                title={this.props.action.label ?? this.props.action.name!}
            >
                {this.props.action.label ?? this.props.action.name!}
            </Button>
        );
    }
}

interface FormActionsProps {
    form: Form;
    onActionAsync?: (action: Action, form: Form) => Promise<any>;

    // TODO: this should be inferred from the action, not hardcoded
    // ...
    variant?: VARIANT;
    color?: COLOR;
    align?: ALIGN;
}

interface IFormActionsState {
    isProcessing: boolean;
}

@observer
export class FormActions extends React.Component<FormActionsProps, IFormActionsState> {
    constructor(props: FormActionsProps) {
        super(props);

        this.state = {
            isProcessing: false
        };
    }

    onActionClick = (action: Action) => async () => {
        const { onActionAsync } = this.props;

        if (this.state.isProcessing) return;

        this.setState({
            isProcessing: true
        });

        const result = onActionAsync ? await onActionAsync(action, this.props.form) : await this.props.form.executeAsync(action);

        this.setState({
            isProcessing: false
        });

        return result;
    };

    renderAction(action: Action, style: React.CSSProperties | undefined): JSX.Element | undefined {
        if (this.props.form.isHidden(action)) return undefined;
        var disabled = this.props.form.isDisabled(action);

        return (
            <ActionButton
                color={this.props.color}
                variant={this.props.variant}
                key={action.name}
                disabled={disabled || this.state.isProcessing}
                action={action}
                onClick={this.onActionClick(action)}
                style={style}
            />
        );
    }

    render() {
        const { form } = this.props;
        const { actions, error } = form ?? {};
        if (!actions) return undefined;

        // var style: React.CSSProperties;
        var other: React.CSSProperties = { marginLeft: 12 };

        // if (!this.props.align) {
        //     let nActions = this.props.form.actions.length;
        //     style = { textAlign: nActions === 1 ? "right" : "center" };
        // } else {
        //     style = { textAlign: this.props.align };
        // }

        if (error) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {
                        error && <Alert severity="warning">{error}</Alert>
                    }
                    <div>
                        {actions.map((action, index) => this.renderAction(action, index > 0 ? other : undefined))}
                    </div>
                </div>
            );
        }

        return (
            <div>
                {actions.map((action, index) => this.renderAction(action, index > 0 ? other : undefined))}
            </div>
        );
    }
}
