import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { css, StyleSheet } from "aphrodite";
import * as api from "@crochik/pi-api";

import { Accordion, AccordionDetails, AccordionSummary, Alert, Card, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import App from "../../../../application/App";
import { Form } from "../../../Form";
import { Loading } from "../../Loading";
import { FieldProps } from "../FieldProps";
import { RenderActions } from "./Actions";
import { ConfirmAppointmentDialog, IConfirmAppointmentDialogProps } from "./ConfirmAppointmentDialog";
import { Scheduler } from "./Scheduler";
import { CurrentAppointment } from "./CurrentAppointment";
import { IAppointmentComponentProps } from "./IAppointmentComponentProps";
import { SchedulerDialog } from "./SchedulerDialog";
import { parseError } from "../../../../api/parseError";

interface IProps extends FieldProps {
    form?: Form;
}

export function AppointmentComponent(props: IAppointmentComponentProps) {
    const { style, disabled, value, onChange, options, label, parentObjectType, parentObjectId, fieldName, reload, inline, title, timeZone } = props;
    const [appointment, setAppointment] = useState<api.ExtendedAppointment>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isScheduling, setScheduling] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmAppointmentDialogProps>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (!value) {
            setAppointment(undefined);
            setLoading(false);
            return;
        }

        setLoading(true);

        // TODO: could replace with new "generic" get
        // ...
        new api.AppointmentSchedulerApi(App().apiConfig)
            .appointmentSchedulerGetAppointment(value)
            .then((result) => {
                setAppointment(result);
            })
            .catch((reason) => {
                setLoading(false);
                setError(parseError(reason));
            });
    }, [value]);

    useEffect(() => {
        if (!isScheduling) {
            setConfirmDialogProps(undefined);
        }
    }, [isScheduling]);

    useEffect(() => {
        setScheduling(false);
        setConfirmDialogProps(undefined);
        if (appointment) {
            setLoading(false);
        }
    }, [appointment]);

    const computedStyle = style || {
        marginTop: disabled ? 8 : 0,
        marginBottom: 8,
    };

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (isLoading) {
        return <Loading />;
    }

    const onCloseScheduler = () => {
        setScheduling(false);
    };

    const onToggleScheduler = () => {
        setScheduling(!isScheduling);
    };

    const updateAppointment = (appointment?: api.Appointment) => {
        if (parentObjectType) {
            // part of an object, reload entire form
            setAppointment(undefined);
            reload?.();
            return;
        }

        onChange?.(appointment?.id);
    };

    const onCancelAppointment = () => {
        setConfirmDialogProps({
            onBack: onCloseConfirmDialog,
            onConfirm: onConfirmCancelAppointment,
            title: "Cancel Appointment",
        });
    };

    const onCloseConfirmDialog = () => setConfirmDialogProps(undefined);

    const onConfirmCancelAppointment = (notes: string) => {
        if (!appointment || !appointment.id) {
            console.error("No appointment to cancel");
            return;
        }

        setLoading(true);
        setConfirmDialogProps(undefined);

        // new api.AppointmentSchedulerApi(App().apiConfig)
        //     .cancelAppointment({
        //         parentObjectType,
        //         parentFieldName: fieldName,
        //         parentObjectId,
        //         appointmentId: appointment.id,
        //         notes,
        //     })
        //     .then((result) => {
        //         runInAction(() => {
        //             updateAppointment(result);
        //             setLoading(false);
        //         });
        //     })
        //     .catch((reason) => {
        //         runInAction(() => {
        //             setLoading(false);
        //             setError(parseError(reason));
        //         });
        //     });

        throw "Not implemented";
    };

    const onScheduleAppointment = (slot: api.TimeSlot, allowUnavailable: boolean, userId?: string) => {
        if (!!slot?.start)
            setConfirmDialogProps({
                onBack: onCloseConfirmDialog,
                onConfirm: onConfirmScheduleAppointment(slot, allowUnavailable, userId),
                title: "Schedule Appointment",
                date: slot.start,
            });
    };

    const onConfirmScheduleAppointment = (slot: api.TimeSlot, allowUnavailable: boolean, userId?: string) => (notes: string) => {
        if (!slot) return;

        setLoading(true);
        setConfirmDialogProps(undefined);
        onCloseScheduler();

        // new api.AppointmentSchedulerApi(App().apiConfig)
        //     .scheduleAppointment({
        //         parentObjectType,
        //         parentFieldName: fieldName,
        //         parentObjectId,
        //         start: slot.start,
        //         end: slot.end,
        //         userId,
        //         notes,
        //         rescheduleAppointmentId: appointment?.id,
        //     })
        //     .then((result) => {
        //         runInAction(() => {
        //             updateAppointment(result);
        //         });
        //     })
        //     .catch((reason) => {
        //         runInAction(() => {
        //             setLoading(false);
        //             setError(parseError(reason));
        //         });
        //     });

        throw "Not implemented";
    };

    const filter = {
        [`${parentObjectType}Id`]: parentObjectId,
    };

    if (!appointment) {
        if (inline) {
            return (
                <>
                    <div className={css(styles.inline)}>
                        <Card className={css(styles.schedulerCard)}>
                            {title && (
                                <>
                                    <Typography color="primary" variant="h6">
                                        {title}
                                    </Typography>
                                    <br />
                                </>
                            )}
                            <Scheduler
                                onClose={onCloseScheduler}
                                title={`Schedule ${label} (${timeZone})`}
                                filter={filter}
                                onScheduleAppointment={onScheduleAppointment}
                                timeZone={timeZone}
                            />
                        </Card>
                    </div>
                    {!!confirmDialogProps && <ConfirmAppointmentDialog {...confirmDialogProps} timeZone={timeZone} />}
                </>
            );
        }

        return (
            <>
                {!disabled && (
                    <div style={computedStyle}>
                        <RenderActions
                            appointment={appointment}
                            leadId={parentObjectId}
                            options={options as api.ReferenceFieldOptions}
                            disabled={isScheduling}
                            onLaunchScheduler={() => setScheduling(true)}
                            onCancelAppointment={onCancelAppointment}
                        />
                    </div>
                )}
                {isScheduling && (
                    <SchedulerDialog
                        onClose={onCloseScheduler}
                        title={`Schedule ${label} (${timeZone})`}
                        filter={filter}
                        onScheduleAppointment={onScheduleAppointment}
                        timeZone={timeZone}
                    />
                )}
                {!!confirmDialogProps && <ConfirmAppointmentDialog {...confirmDialogProps} timeZone={timeZone} />}
            </>
        );
    }

    if (inline) {
        return (
            <>
                <div className={css(styles.inline)}>
                    <Accordion expanded={isScheduling} onChange={onToggleScheduler} className={css(styles.scheduler)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <CurrentAppointment
                                {...props}
                                appointment={appointment}
                                onCancelAppointment={onCancelAppointment}
                                isScheduling={isScheduling}
                                setScheduling={setScheduling}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Scheduler
                                filter={filter}
                                onScheduleAppointment={onScheduleAppointment}
                                timeZone={timeZone}
                            // appointment={appointment}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
                {!!confirmDialogProps && <ConfirmAppointmentDialog {...confirmDialogProps} timeZone={timeZone} />}
            </>
        );
    }

    return (
        <>
            <CurrentAppointment
                {...props}
                appointment={appointment}
                onCancelAppointment={onCancelAppointment}
                isScheduling={isScheduling}
                setScheduling={setScheduling}
            />

            {isScheduling && (
                <SchedulerDialog
                    onClose={onCloseScheduler}
                    title={`Reschedule ${label} (${timeZone})`}
                    filter={filter}
                    onScheduleAppointment={onScheduleAppointment}
                    appointment={appointment}
                    timeZone={timeZone}
                />
            )}
            {!!confirmDialogProps && <ConfirmAppointmentDialog {...confirmDialogProps} timeZone={timeZone} />}
        </>
    );
}

export function AppointmentField(props: IProps) {
    const { form, field, onChange } = props;
    const { options } = field;

    const [leadId, setLeadId] = useState<string>();
    const [timeZone, setTimeZone] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        switch (form?.objectType) {
            case "Lead": {
                const { _id } = form.values as any;
                if (!_id) {
                    setError("Missing Lead");
                    return;
                }

                setLeadId(_id);
                break;
            }

            case "Appointment": {
                const { LeadId } = form.values as any;
                if (!LeadId) {
                    setError("Missing Lead");
                    return;
                }

                setLeadId(LeadId);
                break;
            }

            default:
                setError("Unexpected form");
                return;
        }

        const { EntityId, TimeZoneId } = form.values as any;

        if (!TimeZoneId) {
            if (!EntityId) {
                setError("Unexpected Organization");
                return;
            }

            // TODO: use new api client
            // ...
            new api.CustomObjectApi(App().apiConfig)
                .customObjectGetObject("Organization", EntityId)
                .then(organization => {
                    const { TimeZoneId } = organization as any;
                    if (!TimeZoneId) {
                        setError("Couldn't determine Time Zone");
                        return;
                    }
                    setTimeZone(TimeZoneId);
                })
                .catch(reason => {
                    setError(parseError(reason))
                });
        } else {
            setTimeZone(TimeZoneId);
        }

    }, [form?.objectType, form]);

    const onReload = useCallback(() => {
        form?.reloadAsync();
    }, [form]);

    const onChangeAppointment = useCallback(
        (value?: string) => {
            onChange?.(field, value);
        },
        [onChange, field]
    );

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (!leadId || !timeZone || !form) return <Loading />;

    return (
        <AppointmentComponent
            {...props}
            options={options as api.ReferenceFieldOptions}
            onChange={onChangeAppointment}
            label={field.label ?? field.name ?? ""}
            parentObjectId={leadId}
            parentObjectType={form.objectType ?? "Lead"}
            reload={onReload}
            fieldName={field.name ?? "NextAppointmentId"}
            timeZone={timeZone}
        />
    );
}

const styles = StyleSheet.create({
    inline: {
        maxWidth: 1000,
    },
    scheduler: {
        padding: 12,
    },
    schedulerCard: {
        padding: 24,
    },
    apptCancelled: {
        width: '400px',
        margin: 8,
        border: '2px dashed gray',
        opacity: .5,
    },
    apptInThePast: {
        color: 'gray',
    },
    appt: {
        color: 'black',
    },
    card: {
        width: '400px',
        margin: 8,
    },
});
