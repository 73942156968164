import * as api from "@crochik/pi-api";
import { IPage } from "src/pi/context/IPage";
import { RequestError } from "../api/Client";
import { Default } from "../context/AppContext";
import { Service } from "../context/Services";

export interface Query {
    forceReload?: boolean;
    query?: object;
    args?: any[];
    skip?: number;
    top?: number;
}

// export enum Operator {
//     Eq = "Eq" as any,
//     Ne = "Ne" as any,
//     Gt = "Gt" as any,
//     Gte = "Gte" as any,
//     Lt = "Lt" as any,
//     Lte = "Lte" as any,
// }

export interface Condition extends api.Condition {}

export interface IDataViewRequest extends api.DataViewRequest {}

export interface IDataResponse {
    message?: string|null;
    nextUrl?: string|null;

    // added by the client
    error?: RequestError;
}

export interface IDataViewResponse extends api.DataViewResponse, IDataResponse {}

export interface IUserAction {
    eventId?: string;
    name?: string;
    form?: api.Form;
}

export interface IDataFormActionRequest extends api.DataFormActionRequest {
    // action: string;
    parameters: {
        [key: string]: any;
    };
    // selectedIds: Array<string>;
}

export interface IDataFormActionResponse extends IDataResponse {
    action: string;
    success: boolean;
    ids?: Array<string>;
}

export interface IReferenceValue {
    id: string;
    value: string;
    description?: string;
    imageUrl?: string;
}

export class DataService extends Service {
    selectAsync(entity: string, query?: Query): Promise<any> {
        throw new Error("Not Implemented");
    }
    oneAsync(entity: string, query?: Query): Promise<any> {
        throw new Error("Not Implemented");
    }
    dataViewAsync(name: string, request: IDataViewRequest): Promise<IDataViewResponse | undefined> {
        throw new Error("Not Implemented");
    }
    dataViewUploadAsync(path: string, file: File): Promise<IDataFormActionResponse | undefined> {
        throw new Error("Not Implemented");
    }
    exportDataViewAsync(name: string, request: IDataViewRequest, filename: string): Promise<string | undefined> {
        throw new Error("Not Implemented");
    }
    dataFormAsync(path: string): Promise<api.Form | undefined> {
        throw new Error("Not Implemented");
    }
    dataFormActionAsync(path: string, request: IDataFormActionRequest): Promise<IDataFormActionResponse> {
        throw new Error("Not Implemented");
    }
    dataFileActionAsync(path: string, request: IDataFormActionRequest, filename: string): Promise<string | undefined> {
        throw new Error("Not Implemented");
    }
    lookupAsync(entity: string, request?: IDataViewRequest): Promise<IReferenceValue[]> {
        throw new Error("Not Implemented");
    }
    pageAsync(url: string): Promise<IPage | undefined> {
        throw new Error("Not Implemented");
    }
}

export function register(service: DataService) {
    Default.services.register(DataService, service);
}

export default function get(): DataService {
    return Default.services.getService(DataService);
}
