import { action, toJS } from "mobx";
import moment from "moment";

import * as services from "src/pi/services/DataService";

import * as api from "@crochik/pi-api";
import * as o365 from "src/apis/o365";
import * as singerapi from "src/apis/singer";

import { Session } from "../Session";
import * as API from "./API";

import { AppointmentType, LeadType, Operator, Organization } from "@crochik/pi-api";
import { IPage } from "src/pi/context/IPage";
import { Client, parseResponseError } from "../../pi/api/Client";
import { URI } from "../../pi/api/URI";
import App from "../../pi/application/App";

function sort<T extends { name?: string|null }>(query?: services.Query, rows?: T[]): T[] {
    if (!rows) throw new Error("didn't get any results");
    if (query && query.query && "orderBy" in query.query) {
        rows.sort((l, r) => (l.name || "").localeCompare(r.name || ""));
    }

    return rows;
}

class DataService extends services.DataService {
    selectMapping: { [entity: string]: (query?: services.Query) => Promise<any> } = {};
    oneMapping: { [entity: string]: (query?: services.Query) => Promise<any> } = {};
    users: { [id: string]: api.User } = {};
    leadTypes?: { [id: string]: api.LeadType } = undefined;

    static getId(query?: services.Query) {
        console.log(query);

        if (!query) {
            throw new Error("Could not find id");
        }

        if (query.query && "id" in query.query) {
            return query.query["id"];
        }

        if (!query.args || query.args.length !== 1) {
            throw new Error("Could not find id");
        }

        return query.args[0];
    }

    async dataViewAsync(name: string, request: services.IDataViewRequest): Promise<services.IDataViewResponse | undefined> {
        const client = App().apiClient;
        if (!client) {
            return Promise.reject("No session");
        }

        const uri = new URI(`scheme:${name}`);
        if (uri.query) {
            uri.searchParams.forEach((v, k) =>
                request.criteria?.push({
                    fieldName: k,
                    value: v,
                })
            );
            name = uri.path;
        }

        // const index = name.indexOf("?");
        // if (index > 0) {
        //     const uri = new URI(`${this.config.basePath}${name}`);
        //     uri.searchParams.forEach((v, k) =>
        //         request.criteria?.push({
        //             fieldName: k,
        //             value: v,
        //         })
        //     );
        //     name = name.substring(0, index);
        // }

        const path = uri.getPath("/Dataview");

        return await client.postJson<services.IDataViewResponse>(path, request);
    }

    async dataViewUploadAsync(path: string, file: File): Promise<services.IDataFormActionResponse | undefined> {
        const client = App().apiClient;
        if (!client) {
            return Promise.reject("No session");
        }

        var formData = new FormData();
        formData.append("file", file);

        const url = `${path}/Import`;
        return await client.postForm<services.IDataFormActionResponse>(url, formData);
    }

    exportDataViewAsync(path: string, request: services.IDataViewRequest, filename: string): Promise<string | undefined> {
        return this.generateCSVFile(`${path}/Dataview`, request, filename);
    }

    async dataFormAsync(path: string): Promise<api.Form | undefined> {
        const client = App().apiClient;
        if (!client) {
            return Promise.reject("No session");
        }

        console.log("dataForm", path);
        var uri = new URI(`dataform:${path}`);

        const json = await client.getJson<api.Form>(uri.getPathAndQuery("/DataForm"));
        if (!json) return json;

        return api.FormFromJSONTyped(json, false);
    }

    async pageAsync(path: string, queryArgs?: { [key: string]: any }): Promise<IPage | undefined> {
        const client = App().apiClient;
        if (!client) {
            return Promise.reject("No session");
        }

        // TODO: parse the path to get any query parameters from it before modifying the path
        // ...

        var uri = new URI(`page:${path}`);

        // TODO: add queryArgs to url
        // ...

        const json = await client.getJson<object>(uri.getPathAndQuery("/DataPage"));
        if (!json) return json;

        return api.PageFromJSONTyped(json, false);
    }

    dataFileActionAsync(path: string, request: services.IDataFormActionRequest, filename: string): Promise<string | undefined> {
        return this.generateCSVFile(`${path}/DataFile`, request, filename);
    }

    async dataFormActionAsync(path: string, request: services.IDataFormActionRequest): Promise<services.IDataFormActionResponse> {
        const client = App().apiClient;
        if (!client) {
            return Promise.reject("No session");
        }

        const { action, parameters, selectedIds, view } = request;

        var hasFile = false;
        for (var key of Object.keys(parameters)) {
            const value = parameters[key];
            if (value instanceof File) {
                hasFile = true;
                break;
            }
        }

        var uri = new URI(`action:${path}`);
        var pathAndQuery = uri.getPathAndQuery("/DataForm");

        if (hasFile) {
            var formData = new FormData();
            for (key of Object.keys(parameters)) {
                const value = parameters[key];
                formData.append(key, value);
            }

            // const url = `${path}/DataForm`;
            return await client.postForm<services.IDataFormActionResponse>(pathAndQuery, formData).catch(async (error) => {
                const message = await parseResponseError(error);
                console.error("fail to fetch", error, message);
                const errorResponse: services.IDataFormActionResponse = {
                    success: false,
                    message: message,
                    action: "#cancel",
                };

                return errorResponse;
            });
        }

        return await client
            .postJson<any>(pathAndQuery, {
                action,
                parameters,
                selectedIds,
                view,
            })
            .catch(async (error) => {
                const message = await parseResponseError(error);
                console.error("fail to fetch", error, message);

                const errorResponse: services.IDataFormActionResponse = {
                    success: false,
                    message: message,
                    action: "#cancel",
                };

                return errorResponse;
            });
    }

    lookupAsync(name: string, request?: services.IDataViewRequest): Promise<services.IReferenceValue[]> {
        const url = name.startsWith("/") ? `${name}/Lookup` : `/api/v1/CustomObject(${name})/Lookup`;

        const client = App().apiClient;
        if (!client) {
            return Promise.reject("No session");
        }

        return client.postJson<services.IReferenceValue[]>(url, request || {});
    }

    private async generateCSVFile(path: string, data: any, filename: string): Promise<string | undefined> {
        const client = App().apiClient;
        if (!client) {
            return Promise.reject("No session");
        }

        const options = {
            method: "POST",
            path,
            data,
        };

        try {
            const response = await client.request(options, { Accept: "text/csv" }).catch((error) => {
                console.error("fail to fetch", error);
                return undefined;
            });

            if (!response) return undefined;

            // override the filename if one is provided
            const header = response.headers.get("Content-Disposition");
            if (header) {
                const pairs = header.split(";");
                pairs.forEach((x) => {
                    var pair = x.split("=");
                    if (pair[0].trim() === "filename" && pair.length === 2) {
                        filename = pair[1];
                        if (filename[0] === "'" || filename[0] === '"') {
                            filename = filename.substr(1, filename.length - 2);
                        }
                    }
                });
            }

            const csv = await response.text().catch((error) => {
                console.error("fail to get body", error);
                return undefined;
            });

            if (!csv) return undefined;

            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                link.setAttribute("href", URL.createObjectURL(blob));
                link.setAttribute("download", filename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            return csv;
        } catch (reason) {
            console.error(reason);
            return undefined;
        }
    }

    constructor() {
        super();

        this.selectMapping = {
            // AccountLeads: this.searchAccountLeads,
            AccountUsers: this.getAccountUsers,
            // AppointmentAggregationByCreation: (query) => this.getAppointmentAggregation(false, query),
            // AppointmentAggregationByStart: (query) => this.getAppointmentAggregation(true, query),
            // AppointmentIntegrations: this.appointmentIntegrations,
            // Appointments: this.appointments,
            // AppointmentsByType: this.appointments,
            AppointmentType: this.getAppointmentTypes,
            AppointmentTypes: this.getAppointmentTypes,
            Availability: () => API.getAvailability(),
            AvailabilityStats: this.getAvailabilityStats,
            Event: () => this.getO365Events(),
            // EventType: this.eventTypes,
            // FlowActions: this.flowActions,
            // Flow: this.getFlows,
            // Flows: this.getFlows,
            // FlowSteps: (query) => API.flow.getSteps(query && query.args ? query.args[0] : undefined),
            // FlowEvent: this.getFlowEvents,
            // FlowTrigger: this.getFlowTriggers,
            FullCalendar: this.getCalendar,
            OrgAvailability: this.getOrgAvailability,
            Invoice: this.getInvoices,
            LeadAggregation: this.getLeadsAggregation,
            LeadAggregationForAccount: this.getLeadsAggregationForAccount,
            LeadsPerHourAggregation: this.getLeadsPerHourAggregation,
            LeadsPerHourAggregationForAccount: this.getLeadsPerHourAggregationForAccount,
            // LeadAppointments: this.leadAppointments,
            LeadEvents: (query) => API.getLeadEvents(DataService.getId(query)),
            LeadIntegrations: this.leadIntegrations,
            // Leads: this.searchLeads,
            // LeadStatus: this.getLeadStatus,
            // LeadTypes: () => API.getLeadTypes(),
            MergeUsers: this.getMergeCandidates,
            OpenSlots: () => API.getOpenSlots(),
            Organizations: () => API.getOrganizations(),
            OrgMetaData: (query) => API.metaData.getForOrg(query && query.args ? query.args[1]["id"] : ""),
            // OrgAppointments: this.orgAppointments,
            OrgUsers: this.getOrgUsers,
            // OrgLeads: this.searchOrgLeads,
            // SearchAppointments: this.searchOrgAppointments,
            PossibleUsers: this.getPossibleUsers,
            UserIdentities: () => API.getUserIdentities(),
            User: this.getUsers,
            Users: this.getUsers,
            SingerConfig: () => API.importConfig.get(),
            SingerJobs: (query) => this.getSingerJobs(query as services.Query),
            SingerJobSummary: this.getSingerJobSummary,
        };

        this.oneMapping = {
            AppConfig: this.getAppConfig,
            // Appointment: this.getAppointment,
            AppointmentType: this.getAppointmentType,
            // Flow: this.getFlow,
            // FlowAction: this.getFlowAction,
            // Lead: this.getLead,
            LeadType: this.getLeadType,
            LeadTypeSettings: this.getLeadTypeSettings,
            User: this.getUser,
            Organization: this.getOrganization,
        };
    }
    getO365Events(): Promise<o365.CalendarEvent[]> {
        const api = new o365.EventApi(App().clientConfiguration);
        return api.getEvents();
    }

    getInvoices = async (query?: services.Query): Promise<api.Invoice[]> => {
        if (!query?.query) {
            console.error("getInvoices", query?.query);
            return Promise.reject("Invalid or missing query");
        }

        console.log("getInvoices", query?.query);

        const response = await new api.StatementApi(App().apiConfig).statementGetInvoicesDataView({
            criteria: query.query as api.Condition[],
        });

        console.log(response);

        return response.result as api.Invoice[];
    };

    async getSingerJobSummary(query?: services.Query): Promise<singerapi.SingerJobSummary[]> {
        if (!query?.args || query.args.length < 3) {
            console.error("getSingerJobs", query?.args);
            return Promise.reject("Invalid or missing arguments");
        }

        const config = query.args[1] as singerapi.SingerImportConfig;
        if (!config.id) {
            console.error("getSingerJobs", config);
            return Promise.reject("Invalid config");
        }

        return API.importJob.getSummary(config.id);
    }

    async getSingerJobs(query?: services.Query): Promise<singerapi.SingerJob[]> {
        if (!query?.args || query.args.length < 3) {
            console.error("getSingerJobs", query?.args);
            return Promise.reject("Invalid or missing arguments");
        }

        const config = query.args[1] as singerapi.SingerImportConfig;
        if (!config.id) {
            console.error("getSingerJobs", config);
            return Promise.reject("Invalid config");
        }

        return new singerapi.JobApi(App().clientConfiguration).getJobsById(config.id, query.top, undefined, query.skip);
        // return API.importJob.get(config.id);
    }

    async getUsers(query?: services.Query): Promise<api.User[]> {
        const users = query?.args?.length === 1 ? await API.getOrgUsers(query.args[0]) : await API.getUsers();

        return sort(query, users);
    }

    // async getFlows(query?: services.Query): Promise<api.Flow[]> {
    //     return sort(query, await API.flow.all());
    // }
    //
    // async flowActions(query?: services.Query): Promise<api.FlowAction[]> {
    //     console.log("get flow actions");
    //     if (query?.args && query.args.length === 1) {
    //         const { eventTypeId, flowId } = query.args[0];
    //         if (eventTypeId) {
    //             return sort(query, await API.flowAction.getForTrigger(flowId, eventTypeId));
    //         }
    //     }
    //
    //     // var rows = await API.flowAction.all();
    //     // return sort(query, rows);
    //     return Promise.reject("not implemented");
    // }

    // async getFlow(query?: services.Query): Promise<api.Flow> {
    //     if (query?.args && query.args.length === 1) {
    //         const id = query.args[0] as string;
    //         return await API.flow.getById(id);
    //     }
    //
    //     throw new Error("Invalid arguments");
    // }

    // async getFlowAction(query?: services.Query): Promise<api.FlowAction> {
    //     if (query?.args && query.args.length === 1) {
    //         // const id = query.args[0] as string;
    //         // const map = await API.flowAction.get();
    //         // return map[id];
    //         return Promise.reject("not implemented");
    //     }
    //
    //     throw new Error("Invalid arguments");
    // }

    // async getFlowTriggers(query?: services.Query): Promise<api.EventType[]> {
    //     var list: api.EventType[] = [];
    //     for (var event of await API.eventType.all()) {
    //         if (event.trigger && event.trigger.type === api.TriggerType.User) {
    //             list.push(event);
    //             continue;
    //         }
    //     }
    //
    //     return sort(query, list);
    // }

    // async getFlowEvents(query?: services.Query): Promise<api.EventType[]> {
    //     var list: api.EventType[] = [];
    //
    //     var rows = await API.eventType.all();
    //
    //     if (query?.args && query.args.length === 1) {
    //         const { type: objectType } = query.args[0];
    //         rows = rows.filter((e) => e.objectType === objectType);
    //     }
    //
    //     for (var event of rows) {
    //         if (!event.trigger || event.trigger.type === api.TriggerType.System || event.trigger.type === api.TriggerType.SideEffect) {
    //             list.push(event);
    //             continue;
    //         }
    //     }
    //
    //     return sort(query, list);
    // }
    //
    // async eventTypes(query?: services.Query): Promise<api.EventType[]> {
    //     return sort(query, await API.eventType.all());
    // }

    async getAppointmentTypes(query?: services.Query): Promise<api.AppointmentType[]> {
        return sort(query, await API.getAppointmentTypes());
    }

    // async getLeadStatus(query?: services.Query): Promise<api.LeadStatus[]> {
    //     return sort(query, await API.leadStatus.all());
    // }

    // async getLeadTypesDict(): Promise<{ [id: string]: api.LeadType }> {
    //     if (!this.leadTypes) {
    //         const leadTypes = await API.getLeadTypes();
    //         const list: { [id: string]: api.LeadType } = {};
    //         for (var leadType of leadTypes) {
    //             if (!leadType.id) continue;
    //             list[leadType.id] = leadType;
    //         }
    //         this.leadTypes = list;
    //     }

    //     return this.leadTypes;
    // }

    addSearchArgs(start: number, query?: services.Query): api.Condition[] {
        if (!query?.args) return [];

        console.log("addSearchArgs", query.args);

        var criteria: api.Condition[] = [];

        const args = toJS(query.args);
        for (var c = start; c < args.length; c++) {
            const arg = args[c];
            if (arg === "Search" && c + 1 < args.length) {
                const name = args[c + 1];
                if (name && name.length > 1) {
                    criteria.push({
                        fieldName: "Name",
                        value: name,
                    });
                }
                c++;
                continue;
            }

            if (!(arg instanceof Object)) continue;

            if (query.args && c === 0 && "id" in arg) {
                // first argument is the org id
                var { id } = query.args[0];
                criteria.push({
                    fieldName: "OrganizationId",
                    value: id,
                });
            }

            if ("field" in arg) {
                switch (arg.field) {
                    case "CreatedOn":
                        if ("start" in arg) {
                            criteria.push({
                                fieldName: arg.field,
                                operator: Operator.Gte,
                                value: arg.start,
                            });
                        }

                        if ("end" in arg) {
                            criteria.push({
                                fieldName: arg.field,
                                operator: Operator.Lte,
                                value: arg.end,
                            });
                        }
                        break;

                    case "Tool":
                        criteria.push({
                            fieldName: arg.field,
                            operator: Operator.Eq,
                            value: arg.value,
                        });
                        break;

                    default:
                        break;
                }
            }
        }

        return criteria;
    }

    // searchOrgAppointments = async (query?: services.Query): Promise<api.AppointmentSearchResult[]> => {
    //     const criteria = this.addSearchArgs(0, query);
    //     const result = await API.searchAppointments({
    //         criteria,
    //         skip: query?.skip,
    //         top: query?.top
    //     });

    //     if (!result.results) return Promise.reject('failed to get appts');

    //     return result.results;
    // }

    // searchOrgLeads = (query?: services.Query): Promise<api.LeadSearchResult[]> => {
    //     if (!query?.args || query.args.length < 1) {
    //         console.debug('searchOrgLeads', query);
    //         return Promise.reject('Invalid args');
    //     }

    //     const criteria = this.addSearchArgs(1, query);

    //     var { id } = query.args[0];
    //     criteria.push({
    //         fieldName: 'OrganizationId',
    //         value: id
    //     });

    //     return this._searchLeads({
    //         criteria,
    //         skip: query.skip,
    //         top: query.top
    //     });
    // }

    // searchLeads = (query?: services.Query): Promise<api.LeadSearchResult[]> => {
    //     const criteria = this.addSearchArgs(0, query);
    //     return this._searchLeads({
    //         criteria,
    //         skip: query?.skip,
    //         top: query?.top
    //     });
    // }

    // searchAccountLeads = (query?: services.Query): Promise<api.LeadSearchResult[]> => {
    //     if (!query) return Promise.reject('missing query');
    //     const criteria = this.addSearchArgs(0, query);
    //     criteria.push({
    //         fieldName: 'AccountId',
    //         value: Session.get().user?.accountId
    //     });

    //     return this._searchLeads({
    //         criteria,
    //         skip: query.skip,
    //         top: query.top
    //     });
    // }

    // _searchLeads = async (args: api.Search): Promise<api.LeadSearchResult[]> => {
    //     const result = await API.searchLeads(args, 50);

    //     // map assignedEntity and leadStatus
    //     // const users = await this.getUsersDict();
    //     const leads = result && result.results ? result.results : [];

    //     const leadStatus = await API.leadStatus.get();
    //     const leadTypes = await this.getLeadTypesDict();

    //     for (var lead of leads) {
    //         // const entity = lead.assignedEntityId ? users[lead.assignedEntityId] : undefined;
    //         // lead['assignedEntity'] = entity ? entity.name : lead.assignedEntityId;

    //         const status = lead.leadStatusId ? leadStatus[lead.leadStatusId] : undefined;
    //         lead['leadStatus'] = status ? status.name : lead.leadStatusId;

    //         const leadType = lead.leadTypeId ? leadTypes[lead.leadTypeId] : undefined;
    //         lead['leadType'] = leadType ? leadType.name : lead.leadTypeId;
    //     }

    //     return leads;
    // }

    getAccountUsers = async (query?: services.Query): Promise<any> => {
        if (!query) return Promise.reject("missing query");

        var users = API.getUsers();
        var orgs = API.getOrganizations();
        var result = await Promise.all([users, orgs]);

        var orgDict: { [id: string]: api.Organization } = {};
        result[1].forEach((org: api.Organization) => (orgDict[org.id as string] = org));
        var ret = result[0].map((user: api.User) => {
            var org = user.organizationId ? orgDict[user.organizationId] : null;
            return org
                ? {
                      ...user,
                      organization: org.name,
                  }
                : user;
        });

        return ret;
    };

    getMergeCandidates = async (): Promise<any[]> => {
        var rows = await API.getMergeCandidates();
        var mapped = rows.map((src) => {
            if (!src || !src.user1 || !src.user2) return src;
            return {
                ...src,
                key: `${src.user1.entityId}${src.user2.entityId}`,
                identity1: `${src.user1.name}\n${src.user1.email}\n${src.user1.context}`,
                identity2: `${src.user2.name}\n${src.user2.email}\n${src.user2.context}`,
            };
        });

        return mapped;
    };

    getAvailabilityStats(query?: services.Query): Promise<api.AvailabilityStats> {
        var id: string | undefined | null = undefined;

        if (query && query.args && query.args.length === 2) {
            const org = query.args[1] as Organization;
            id = org.id;
        }

        return API.getAvailabilityStats(id);
    }

    // getAppointmentAggregation = async (byStart: boolean, query?: services.Query): Promise<api.AppointmentAggregation | undefined> => {
    //     if (!query || !query.args || query.args.length < 1) {
    //         return Promise.reject("Invalid or missing arguments");
    //     }

    //     var arg = query.args[0];
    //     if (query.args.length === 2) {
    //         const { id } = query.args[1];
    //         return API.getAppointmentAggregationForOrg(id, arg.start, arg.end, byStart);
    //     }

    //     return API.getAppointmentAggregation(arg.start, arg.end, byStart);
    // };

    getLeadsAggregation = async (query?: services.Query): Promise<api.LeadAggregation | undefined> => {
        if (query && query.args && query.args.length === 2) {
            const { id } = query.args[1];
            if (!id) return Promise.reject(`Missing id`);
            return API.getLeadAggregation(id);
        }

        return API.getLeadAggregation();
    };

    getLeadsAggregationForAccount = async (query?: services.Query): Promise<api.LeadAggregation | undefined> => {
        return API.getLeadAggregationForAccount();
    };

    getLeadsPerHourAggregation = async (query?: services.Query): Promise<api.LeadAggregation | undefined> => {
        if (query && query.args && query.args.length === 2) {
            const { id } = query.args[1];
            if (!id) return Promise.reject(`Missing id`);
            return API.getLeadsPerHourAggregation(id);
        }

        return API.getLeadsPerHourAggregation();
    };

    getLeadsPerHourAggregationForAccount = async (query?: services.Query): Promise<api.LeadAggregation | undefined> => {
        return API.getLeadsPerHourAggregationForAccount();
    };

    getOrgUsers = async (query?: services.Query): Promise<api.User[] | undefined> => {
        var orgId: string | undefined;

        if (!query || !query.args || query.args.length !== 2) {
            orgId = undefined;
        } else {
            var org: Organization = query.args[1];
            if (!org.id) {
                Promise.reject("Missing Org Id");
                return;
            }
            orgId = org.id;
        }

        return API.getOrgUsers(orgId);
    };

    getAppConfig = async (query?: services.Query): Promise<api.AppConfig | undefined> => {
        if (!query || !query.args || query.args.length !== 2) {
            Promise.reject("invalid number or args");
            return;
        }

        // ?????
        // if (query.args[0] === "AppointmentType") {
        //     return API.getAppConfig(query.args[1]);
        // }

        Promise.reject("invalid args");
        return undefined;
    };

    getAppointmentType = async (query?: services.Query): Promise<api.AppointmentType | undefined> => {
        if (!query || !query.args || query.args.length !== 2) {
            Promise.reject("invalid number or args");
            return;
        }

        var id = query.args[1];
        return await API.getAppointmentType(id);
    };

    getOrgAvailability = async (query?: services.Query): Promise<api.TimeSlotWithCount[] | undefined> => {
        const client = new api.AvailabilityApi(App().apiConfig);

        if (query?.args?.length !== 1) {
            console.error(query);
            return Promise.reject("Invalid arguments");
        }

        const { organizationId, start, end } = query.args[0];

        return organizationId ? await client.availabilityOrganizationAvailability_3(organizationId, start, end) : await client.availabilityOrganizationAvailability(start, end);
    };

    getCalendar = async (query?: services.Query): Promise<api.EntityOpenSlots | null> => {
        console.log("getCalendar");
        if (query && query.args && query.args.length === 2) {
            // scheduler
            if (query.args[0] === "User") {
                console.log("get calendar for user");
                let args: API.IGetUserAvailability = query.args[1];
                return await API.getCalendar(args);
            }
            Promise.reject("invalid query");
            return null;
        }

        // my calendar
        const start = moment().startOf("day").toDate();
        const end = moment().startOf("day").add("days", 14).toDate();
        return await API.getMyCalendar(start, end);
    };

    getPossibleUsers = async (query?: services.Query): Promise<api.User[] | undefined> => {
        if (!query || !query.args || query.args.length < 2) {
            Promise.reject("Missing args");
            return;
        }

        var args = query.args[1];
        return await API.getPossibleUsers(args["leadId"], args["appointmentTypeId"]);
    };

    // returns array with only one element
    // getLead = async (query?: services.Query): Promise<api.Leads | undefined> => {
    //     if (!query || !query.args || query.args.length < 2 || !query.args[1]['id']) {
    //         Promise.reject('Missing id');
    //         return;
    //     }

    //     const id = query.args[1]['id'];
    //     const lead = await API.getLead(id);

    //     const leadTypes = await this.getLeadTypesDict();
    //     const leadType = leadTypes[lead.leadTypeId as string];
    //     lead['leadType'] = leadType ? leadType.name : lead.leadTypeId;

    //     return lead;
    // }

    getLeadType = async (query?: services.Query): Promise<api.LeadType | undefined> => {
        if (!query || !query.args || query.args.length < 2 || !query.args[1]["id"]) {
            Promise.reject("Missing id");
            return;
        }

        var id = query.args[1]["id"];
        return await API.getLeadType(id);
    };

    getLeadTypeSettings = async (query?: services.Query): Promise<api.LeadTypeSettings | undefined> => {
        if (!query || !query.args || query.args.length < 2 || !query.args[1]["id"]) {
            Promise.reject("Missing id");
            return;
        }

        var id = query.args[1]["id"];
        return await API.getLeadTypeSettings(id);
    };

    // getAppointment = async (query?: services.Query): Promise<api.Appointment | undefined> => {
    //     if (!query || !query.args || query.args.length < 1 || !query.args[0]["id"]) {
    //         Promise.reject("Missing id");
    //         return;
    //     }

    //     var id = query.args[0]["id"];
    //     var appt = await API.getAppointment(id);
    //     if (appt && appt.entityId) {
    //         var user = await this.getUser({ args: ["User", { id: appt.entityId }] });
    //         if (user) appt["userName"] = user.name;
    //     }

    //     return appt;
    // };

    getUser = async (query?: services.Query): Promise<api.User | undefined> => {
        var id: string;
        if (!query || !query.args || query.args.length < 2 || !query.args[1]["id"]) {
            var currUser = Session.get().user;
            if (!currUser || !currUser.id) {
                Promise.reject("Missing user Id");
                return;
            }
            id = currUser.id;
        } else {
            id = query.args[1]["id"];
        }

        if (id in this.users) return this.users[id];

        var user = await new api.UserApi(App().apiConfig).userGetById(id);
        if (!user || !user.id) return Promise.reject(`User not found: ${id}`);

        this.users[user.id] = user;
        return user;
    };

    getOrganization = async (query?: services.Query): Promise<api.Organization | undefined> => {
        if (!query || !query.args || query.args.length < 2 || !query.args[1]["id"]) {
            return await new api.OrganizationApi(App().apiConfig).organizationGetOrganization();
        }

        const { id } = query.args[1];
        return await new api.OrganizationApi(App().apiConfig).organizationGetOrganizationById(id);
    };

    // addUser = async (appts: api.Appointment[]) => {
    //     var users = await this.getUsersDict();
    //     for (var c = 0; c < appts.length; c++) {
    //         var appt = appts[c];
    //         var user = users[appt.entityId as string];
    //         appt['userName'] = user ? user.name : '';
    //     }
    // }

    // appointments = async (query?: services.Query): Promise<api.Appointment[] | undefined> => {
    //     var appointmentTypeId: string | undefined = undefined;
    //     if (query && query.query) {
    //         var appointmentType = query.query as api.AppointmentType;
    //         if (!appointmentType || !appointmentType.id) {
    //             Promise.reject("Missing Type");
    //             return;
    //         }
    //         appointmentTypeId = appointmentType.id;
    //     }

    //     if (!appointmentTypeId) return Promise.reject("Missing type");

    //     const client = new api.AppointmentApi(getApiConfig());
    //     return await client.getOrgAppointmentsByType(appointmentTypeId);
    // };

    // orgAppointments = async (query?: services.Query): Promise<api.Appointment[] | undefined> => {
    //     const client = new api.AppointmentApi(getApiConfig());
    //     const start = moment().startOf("day").toDate();

    //     if (query?.args?.length === 2) {
    //         const filter = query.args[0];
    //         const id = query.args[1];

    //         switch (filter) {
    //             case "AppointmentType":
    //                 return await client.getOrgAppointmentsByType(id);

    //             case "Organization":
    //                 return await client.getAppointmentsForOrg(id, start);

    //             default:
    //                 return Promise.reject("invalid filter");
    //         }
    //     }

    //     return await client.getOrgAppointments(start);
    // };

    // leadAppointments = async (query?: services.Query): Promise<api.Appointment[] | undefined> => {
    //     if (!query || !query.query) {
    //         Promise.reject("Missing query");
    //         return;
    //     }

    //     var leadId = query.query["id"];
    //     var appts = await API.getLeadAppointments(leadId);
    //     // await this.addUser(appts);

    //     return appts;
    // };

    // appointmentIntegrations = async (query?: services.Query): Promise<any> => {
    //     if (!query || !query.query) {
    //         Promise.reject("Missing query");
    //         return;
    //     }

    //     var appointmentId = query.query["id"];
    //     return API.getAppointmentIntegrations(appointmentId);
    // };

    leadIntegrations = async (query?: services.Query): Promise<any> => {
        if (!query || !query.query) {
            Promise.reject("Missing query");
            return;
        }

        var leadId = query.query["id"];
        return API.getLeadIntegrations(leadId);
    };

    @action
    async selectAsync(entity: string, query?: services.Query): Promise<any> {
        const getter = this.selectMapping[entity];
        if (!getter) {
            console.error(`${entity}: data service not implemented`);
            return [];
        }

        const json = await getter(query);
        return json;
    }

    @action
    async oneAsync(entity: string, query?: services.Query): Promise<any> {
        let getter = this.oneMapping[entity];
        if (!getter) {
            console.error(`${entity}: data service not implemented`);
            return [];
        }

        let json = await getter(query);

        return json;
    }
}

export default function register() {
    services.register(new DataService());
}
