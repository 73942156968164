import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { ReactNode, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useForm } from "src/pi/hooks/useForm";
import { FormCell, FormCellProps } from "../FormCell";
import { DraggableItemTypes } from "./DraggableItemTypes";
import * as api from "@crochik/pi-api";

import { FieldTypeIcon } from "./FieldTypeIcon";

interface Props extends FormCellProps {
    row: number;
    hidden?: boolean;
    onShowInfo?(field: api.FormField): void;
    onRender?(field: api.FormField): ReactNode;
}

export interface DraggableFormCellItem {
    currentFieldId: number;
    currentRowId: number;
    field: api.FormField;
    hidden?: boolean;
    newFieldId?: number;
    newRowId?: number;
}

interface CollectedProps {
    isDragging: boolean;
}

export function DraggableFormCell(props: Props) {
    const { field, hidden, index, row, onShowInfo, onRender } = props;
    const form = useForm();
    const [hovering, setHovering] = useState(false);

    const [{ isDragging }, drag] = useDrag<DraggableFormCellItem, any, CollectedProps>(
        () => ({
            type: DraggableItemTypes.FIELD,
            item: { currentRowId: row, currentFieldId: index, field, hidden },
            previewOptions: {},
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [row, index, field]
    );

    useEffect(() => {
        if (isDragging) {
            setHovering(false);
        }
    }, [isDragging]);

    const onMouseEnter = () => setHovering(true);

    const onMouseLeave = () => setHovering(false);

    const onClickInfo = () => onShowInfo?.(field);


    return (
        <div ref={drag} className={css(styles.container, isDragging && styles.dragging)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={css(styles.draggable)}>
                {
                    !!onRender ? onRender?.(field) :
                        <div className={css(styles.noClickContainer)}>
                            <div className={css(styles.fieldTypeIcon)} title={field.type!}><FieldTypeIcon type={field.type!} /></div>
                            <div style={{ width: '100%', marginLeft: '6px' }}>
                                <FormCell field={field} form={form} index={index} />
                            </div>
                        </div>
                }
                {hovering && onShowInfo && (
                    <div className={css(styles.actions)}>
                        <IconButton style={{ padding: 4 }} onClick={onClickInfo}>
                            <InfoIcon htmlColor="#42A5F5" />
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        position: "relative",
        display: "flex",
        flex: 1,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "transparent",
        borderRadius: 6,
        paddingLeft: 8,
        paddingRight: 8,
        overflow: "auto",
        ":hover": {
            borderColor: "#42A5F5",
            boxShadow: "2px 2px 5px 2px rgba(173,173,173,1)",
        },
    },
    emptyField: {
        fontSize: 16,
        border: "1px solid #eeeeee",
        color: "gray",
        marginTop: 12,
        marginBottom: 4,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 12,
        width: "100%",
    },
    emptyLabel: {},
    draggable: {
        cursor: "move",
        display: "flex",
        flex: 1,
    },
    dragging: {
        opacity: 0.5,
    },
    actions: {
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: 8,
    },
    actionButton: {
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
    },
    noClickContainer: {
        pointerEvents: "none",
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    fieldTypeIcon: {
        width: '48px',
        height: 'calc(100% - 16px)',
        backgroundColor: 'rgb(240, 240, 240)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 12,
        marginBottom: 4,
        boxSizing: 'border-box',
    },
});
