import { InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import Input from "@mui/material/Input";

import { css, StyleSheet } from "aphrodite";
import { InputTextField } from "./InputTextField";

export class MaskTextField extends InputTextField {
    getInputProps() {
        return {
            id: this.props.field.name!,
            onChange: this.onChange,
            onKeyPress: this.onKeyPressed,
            error: this.props.error ? true : false,
            value: this.props.value,
            disabled: this.props.disabled,
        };
    }

    getFormProps() {
        return {
            fullWidth: true,
            margin: "normal",
            style: this.props["style"],
        };
    }

    getInputLabelProps() {
        return {
            required: this.props.field.isRequired,
            error: this.props.error ? true : false,
            htmlFor: this.props.field.name!,
            shrink: true,
        };
    }

    renderError() {
        if (!this.props.error) return null;
        return <FormHelperText error={this.props.error ? true : false}>{this.props.error}</FormHelperText>;
    }

    render() {
        let formProps = this.getFormProps() as any;
        let inputProps = this.getInputProps();
        let inputLabelProps = this.getInputLabelProps();

        return (
            <FormControl
                className={css(styles.formControl, inputProps?.disabled && styles.formControlDisabled)}
                {...formProps}
            >
                <InputLabel className={css(styles.label)} {...inputLabelProps}>
                    {this.props.field.label ?? this.props.field.name!}
                </InputLabel>
                <Input className={css(styles.input)} {...inputProps} />
                {this.renderError()}
            </FormControl>
        );
    }
}

const styles = StyleSheet.create({
    formControl: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        marginBottom: 8,
        marginTop: 16,
    },
    formControlDisabled: {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
    label: {
        top: 14,
    },
    input: {
        margin: 0,
    },
});
