import CloseIcon from "@mui/icons-material/CloseRounded";
import { Dialog, IconButton } from "@mui/material";
import { css, StyleSheet } from "aphrodite";
import * as api from "@crochik/pi-api";

interface Props {
    field?: api.FormField;
    onClose(): void;
}

export function FieldInfoDialog(props: Props) {
    const { field, onClose } = props;
    return (
        <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
            <div className={css(styles.title)}>
                <div>{"Field Info"}</div>
                <IconButton onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={css(styles.fields)}>
                <div className={css(styles.label)}>{"Name"}</div>
                <div>{field?.name || "N/A"}</div>
                <div className={css(styles.label)}>{"Label"}</div>
                <div>{field?.label || "N/A"}</div>
                <div className={css(styles.label)}>{"Type"}</div>
                <div>{field?.type || "N/A"}</div>
            </div>
        </Dialog>
    );
}

const styles = StyleSheet.create({
    title: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 16,
        fontSize: 18,
    },
    fields: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
    },
    label: {
        fontSize: 12,
        fontWeight: "bold",
        marginTop: 8,
    },
});
