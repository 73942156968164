import ClearIcon from '@mui/icons-material/Clear';
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import { IconButton, ListItemIcon, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { BreakpointLayouts } from '@crochik/pi-api';

export interface IBreakpointSelectorProps {
    layouts: BreakpointLayouts;
    value?: number;
    onChange: (event: SelectChangeEvent<number>) => void;
    onClear: (breakpoint: string) => (event: React.MouseEvent) => void;
}

export function BreakpointSelector(props: IBreakpointSelectorProps) {
    const { value: maxWidth, onChange: onChangeMaxWidth, onClear: onClearLayout, layouts } = props;

    return (
        <Select
            id="device-select"
            value={maxWidth}
            inputProps={{ "aria-label": "Without label" }}
            onChange={onChangeMaxWidth}
            sx={{ height: 45, width: 300, display: "flex" }}
        >
            <MenuItem value={1200}>
                <div className={css(styles.menuRow)}>
                    <div className={css(styles.menuItem)}>
                        <ListItemIcon>
                            <PersonalVideoIcon />
                        </ListItemIcon>
                        <div style={{ fontWeight: !!layouts.md || maxWidth === 1200 ? "bold" : "normal" }}>Desktop (Md, 1200)</div>
                    </div>
                    {!!layouts.md && maxWidth !== 1200 && <IconButton onClick={onClearLayout("md")}><ClearIcon /></IconButton>}
                </div>
            </MenuItem>
            <MenuItem value={900}>
                <div className={css(styles.menuRow)}>
                    <div className={css(styles.menuItem)}>
                        <ListItemIcon>
                            <TabletAndroidIcon />
                        </ListItemIcon>
                        <div style={{ fontWeight: !!layouts.sm || maxWidth === 900 ? "bold" : "normal" }}>Tablet (Sm, 900)</div>
                    </div>
                    {!!layouts.sm && maxWidth !== 900 && <IconButton onClick={onClearLayout("sm")}><ClearIcon /></IconButton>}
                </div>
            </MenuItem>
            <MenuItem value={600}>
                <div className={css(styles.menuRow)}>
                    <div className={css(styles.menuItem)}>
                        <ListItemIcon>
                            <SmartphoneIcon />
                        </ListItemIcon>
                        <div style={{ fontWeight: !!layouts.xs || maxWidth === 600 ? "bold" : "normal" }}>Phone (Xs, 600)</div>
                    </div>
                    {!!layouts.xs && maxWidth !== 600 && <IconButton onClick={onClearLayout("xs")}><ClearIcon /></IconButton>}
                </div>
            </MenuItem>
        </Select>
    );
}

export const styles = StyleSheet.create({
    menuRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: '100%',
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",

    },
    title: {
        margin: 0,
        fontSize: 22,
    },
});
