import { observer } from "mobx-react";
import * as React from "react";
import { toJS } from "mobx";
import { FieldProps } from "../FieldProps";
import { Form } from "../../../Form";
import { TYPE } from "../../../../context/IForm";
import { factory } from "../Factory";
import * as api from "@crochik/pi-api";

import { MultiSelectFieldOptions } from "@crochik/pi-api";

export interface IProps extends FieldProps {
    form: Form;
}

function ArrayFieldF(props: IProps) {
    const { form, field, disabled, value, onChange } = props;

    const fieldProps: FieldProps = {
        field: {
            ...field,
            t: "MultiSelectField",
            type: TYPE.MULTISELECT,
            options: {
                t: 'MultiSelectFieldOptions',
                allowUnknown: true,
            } satisfies MultiSelectFieldOptions as any,
        } satisfies api.FormField,
        error: undefined,
        disabled,
        value,
        onChange
    };

    return factory.render(form, fieldProps.field, fieldProps);
}

@observer
export class ArrayField extends React.Component<IProps> {
    render() {
        const { value, field, ...other } = this.props;
        const v = toJS(value);

        return <ArrayFieldF {...this.props} value={v} />;
    }
}