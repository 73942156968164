import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { observer } from "mobx-react";
import React from "react";
import { DataGridCell } from "./DataGridCell";
import { IDataViewComponentProps } from "./IDataViewComponentProps";
import * as api from "@crochik/pi-api";

interface IProps extends IDataViewComponentProps {
    loadedFields: { [name: string]: boolean },
    row: object
    onSelect(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: object): void
    onFilterChange: (field: api.FormField) => (value: object) => void
    onPopup?: (e: React.MouseEvent) => void;
}

function DataGridRowF(props: IProps) {
    const { loadedFields, row, view, onClick, onSelect, onFilterChange, onPopup } = props

    if (!row) {
        console.log("missing row");
        return null;
    }

    const { visibleFields } = view;
    const id = view.id(row);
    const isSelected = view.isSelected(row) || false;
    const { isSelectable, singleObjectActions } = view;

    const onShowPopup = (e: React.MouseEvent) => {
        onPopup?.(e);
    }

    return (
        <TableRow hover={true} tabIndex={-1} key={id} aria-checked={isSelected} selected={isSelected} className={css(styles.tableRow)}>
            {isSelectable && (
                <TableCell padding="none" size="small" style={{ width: 42, paddingLeft: 12 }} onClick={(e) => onSelect(e, row)}>
                    <Checkbox checked={isSelected} />
                </TableCell>
            )}
            {singleObjectActions && (
                <TableCell padding="none" size="small" style={{ width: 42 }}>
                    {/* <IconButton id="showOnHoverButton" className={css(styles.showOnHoverButton)} onClick={onShowPopup}><MoreVertIcon /></IconButton> */}
                    <IconButton onClick={onShowPopup}><MoreVertIcon /></IconButton>
                </TableCell>
            )}
            {visibleFields?.map((field, i) => <DataGridCell key={i} loadedFields={loadedFields} field={field} index={i} row={row} view={view} onClick={onClick} onFilterChange={onFilterChange} />)}
        </TableRow>
    );
}

const styles = StyleSheet.create({
    tableRow: {
        ":hover #showOnHoverButton": {
            visibility: 'visible'
        }
    },
    showOnHoverButton: {
        visibility: 'hidden',
        '@media (hover: none)': {
            visibility: 'visible'
        }
    }
})

@observer
export class DataGridRow extends React.Component<IProps>
{
    render() {
        const { view, row } = this.props;
        const id = view.id(row);

        // just to create the mobx binding so it will re-render when the selected state changes
        view.isSelected(row);

        return <DataGridRowF key={id} {...this.props} />
    }
}