import Card from "@mui/material/Card";
import * as React from "react";

import { Form } from "../Form";

import { ALIGN, COLOR, FormActions, VARIANT } from "./FormActions";
import { FormBody } from "./FormBody/FormBody";
import { FormTitle } from "./FormTitle";

import App from "src/pi/application/App";
import "./Form.css";
import { Action } from "src/pi/context/IForm";

export interface ActionProps {
    variant?: VARIANT;
    color?: COLOR;
    align?: ALIGN;
    onActionAsync?: (action: Action, form: Form) => Promise<any>;
}

interface Props {
    form: Form | string;
    autoFocus?: boolean;
    actionProps?: ActionProps;
    style?: React.CSSProperties;
    title?: string;
}

interface State {
    form?: Form;
}

export class VerticalForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const form = typeof this.props.form === "string" ? undefined : this.props.form;

        this.state = {
            form: form,
        };
    }

    async componentDidMount() {
        if (typeof this.props.form === "string") {
            const form = await App().loadFormAsync(this.props.form);
            this.setState({ form });
        }
    }

    render() {
        const { style, title } = this.props;
        const { form } = this.state;

        if (!form) {
            return <Card className="pi-form"></Card>;
        }

        return (
            <Card className="pi-form" style={style}>
                <FormTitle form={form} title={title} />
                <FormBody {...this.props} form={form} />
                <FormActions {...this.props.actionProps} form={form} />
            </Card>
        );
    }
}
