import { Card } from "@mui/material";
import { css, StyleSheet } from "aphrodite";
import { AppointmentCardContent } from "./AppointmentCardContent";
import { RenderActions } from "./Actions";
import * as api from "@crochik/pi-api";
import { IAppointmentComponentProps } from "./IAppointmentComponentProps";

interface ICurrentAppointmentProps extends IAppointmentComponentProps {
    appointment: api.ExtendedAppointment;
    isScheduling: boolean;
    setScheduling: (value: boolean) => void;
    onCancelAppointment: () => void;
}

export function CurrentAppointment({
                                       title,
                                       appointment,
                                       parentObjectId,
                                       isScheduling,
                                       options,
                                       setScheduling,
                                       onCancelAppointment,
                                       disabled
                                   }: ICurrentAppointmentProps) {
    return (
        <Card className={css(styles.currentAppointment)}>
            <AppointmentCardContent appointment={appointment} />
            {!disabled && (
                <div style={{ marginTop: 16 }}>
                    <RenderActions
                        options={options as api.ReferenceFieldOptions}
                        appointment={appointment}
                        leadId={parentObjectId}
                        disabled={isScheduling}
                        onLaunchScheduler={() => setScheduling(true)}
                        onCancelAppointment={onCancelAppointment}
                    />
                </div>
            )}
        </Card>
    );
}

const styles = StyleSheet.create({
    currentAppointment: {
        // margin: 8,
        padding: 16
    },
});
