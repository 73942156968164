import { FormControl, Popover } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IReferenceValue } from "../../../../services/DataService";
import { AutocompleteBase, AutocompleteBaseProps } from "../Autocomplete/AutocompleteBase";
import { AutocompleteReadonly } from "../Autocomplete/AutocompleteReadonly";
import { FieldProps } from "../FieldProps";
import * as api from "@crochik/pi-api";

interface IProps extends FieldProps {
    displayInline?: boolean;
    autoOpen?: boolean;
}

function BitwiseFlagFieldF(props: IProps) {
    const { field, style, value, onChange, displayInline, autoOpen, disabled } = props;
    const [searchText, setSearchText] = React.useState("");
    const [isOpen, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [pendingValue, setPendingValue] = useState<number>();
    
    const [localValue, setLocalValue] = useState<string[]>();

    const rows = useMemo(() => {
        const options = field.options as api.BitwiseFlagFieldOptions;

        if (!options) {
            return [];
        }

        const { items } = options;

        const list: IReferenceValue[] = [];
        if (items) {
            for (var option of Object.keys(items)) {
                const refValue: IReferenceValue = {
                    id: option,
                    value: items[option]
                };
                list.push(refValue);
            }
        }

        return list;

    }, [field.options]);

    useEffect(()=>{
        if (!value || !rows) {
            setLocalValue([]);
            return;
        }

        const intValue = parseInt(value);
        let selected : string[] = [];
        rows.forEach(x=>{
            const n = parseInt(x.id);
            if (n>0 && (n & intValue) === n) {
                selected.push(x.id);
            }
        });
        setLocalValue(selected);

    }, [value, rows]);

    const selectedMap = useMemo(() => {
        const map: { [id: string]: IReferenceValue } = {};
        rows?.forEach(x => {
            map[x.id] = x;
        });
        return map;
    }, [rows]);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((e) => setSearchText(e.target.value), []);

    const filteredRows = useMemo(() => {
        if (!!searchText?.length) {
            const filtered =  rows.filter((row) => row.value.toLowerCase().includes(searchText.toLowerCase()));
            return filtered;
        }
        return rows;
    }, [rows, searchText]);

    const onValueChange = useCallback((newValue: string[]) => {
        let flags = 0;
        if (newValue) {
            newValue.forEach(x=>{
                flags |= parseInt(x);
            });
        }
        setPendingValue(flags);
        console.log('selected', newValue, flags);

        if (!displayInline) return;
        onChange?.(field, flags);
    }, [displayInline, field, onChange]);

    const onClear = useCallback(() => onChange?.(field, []), []);

    const onOpen = () => {
        setPendingValue(value);
        setOpen(true);
    };

    const onClosePopover = () => {
        if (!displayInline) {
            onChange?.(field, pendingValue);
        }
        setOpen(false);
    };

    // have to wait for the anchor to be set :(
    React.useEffect(() => {
        if (anchorRef.current && autoOpen) {
            setOpen(true);
        }
    }, [autoOpen]);

    const autocompleteBaseProps = {
        searchStr: searchText,
        selectedMap,
        rows: filteredRows,
        value: localValue,
        onInputChange,
        onValueChange,
        label: field.label ?? field.name ?? "",
        onOpen: disabled ? undefined : onOpen,
        disabled
    };

    if (displayInline) {
        return (
            <AutocompleteBase {...autocompleteBaseProps} />
        );
    }

    return (
        <div
            style={{
                display: "flex",
                paddingTop: 16,
                paddingBottom: 8,
                ...style
            }}
            key={field.name}
            ref={anchorRef}
        >
            <FormControl style={{ width: "100%" }}>
                <div style={{cursor: disabled ? undefined : 'pointer'}}>
                    <AutocompleteReadonly {...autocompleteBaseProps} onClear={onClear} />
                </div>
                {isOpen && anchorRef.current &&
                    <Popover
                        open={isOpen}
                        anchorEl={anchorRef.current}
                        onClose={onClosePopover}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                    >
                        <AutocompleteBase {...autocompleteBaseProps} />
                    </Popover>
                }
            </FormControl>
        </div>
    );
}

const styles = StyleSheet.create({
    // anchor: {
    //     cursor: "pointer"
    // }
});


@observer
export class BitwsieFlagField extends React.Component<IProps> {
    render(): React.ReactNode {
        const props = toJS(this.props);
        return <BitwiseFlagFieldF {...props} />;
    }
}
